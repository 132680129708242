function GolcondaFort() {
    return (
        <>
            <div className="main-banner" style={{background: 'url(assets/images/golconda.jpg)', backgroundSize: 'cover'}}>
                <div className="banner-caption">
                    <span>Hyderabad</span>
                    <h1>Golconda Fort</h1>
                </div>
            </div>
            <div className="container">
                <div className="about-destination">
                    <div className="heading">
                        <h3>Golconda Fort</h3>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <p>Located on the Western part of the amazing city of Hyderabad, Golconda Fort is among the region’s best-protected monuments, The construction of this fort was concluded in the 1600s, and it is well-known for being the area where once the mighty koh-I-Noor diamond was kept. </p>
                        </div>
                    </div>

                </div>

                <div className="heading">
                    <h3>Things to Do at Golconda Fort</h3>
                </div>
                <div className="row mt-4 mb-3">
                    <div className="col-md-12">
                        <p style={{textAlign: 'justify', lineHeight: '29px'}}>
                            The light and sound show is considered the prominent attraction of the fort. These shows are conducted in three different languages like- Hindi, English, and Telugu. The show is organised at the remarkable area around and inside the Rani Mahal. Moreover, the show is presented twice a day in two different languages.Furthermore, there are multiple food corners near the fort. You can try various Hyderabadi delicacies there.
                        </p>
                    </div>
                </div>
                <div className="things-to-do">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="heading">
                                <h4>Show Timings at the Golconda Fort </h4>
                            </div>
                            <p>The show timings are differentiated depending on the months you look forward to visiting and witnessing it. </p>
                            <ul>
                                <li>Between November- February, the primary show is organised at around 6:30 pm. Then, the second show is presented at around 7:45 pm.</li>
                                <li>Between March- October, the first show is organised at 7 pm, and the second one is held at approx 8:15 pm. </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src="assets/images/gol-1.jpg" alt="golconda-fort" style={{position: 'relative', top: '-20px', left: '-12px'}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-to-reach">
                <div className="container">
                    <div className="box">
                        <div className="heading">
                            <h3>Mediums to Get to the Golconda Fort</h3>
                        </div>
                        <p>Situated at around 9.8 Km from The Superb Hotels, Golconda Fort is reachable via multiple transportation mediums. You can take a taxi or book a cab online to get there.</p>
                    </div>
                </div>
            </div>
            <div className="additional-details">
                <div className="container">
                    <div className="heading">
                        <h3>Additional Details</h3>
                    </div>
                    <p className="mb-2 text-center" style={{fontSize:'17px'}}>
                        <b>Timings : </b> 9:00 AM- 5:00 PM
                    </p>
                    <p className="text-center" style={{fontSize:'17px'}}>
                        <b>Entry Fees : </b> For Indians- INR 5 | For Foreigners- INR 100 | Still Camera: INR 25
                    </p>
                </div>
            </div>
        </>
    )
}

export default GolcondaFort;