import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchAllData } from "../../services/EndPoint";
import SearchResultComp from "./SearchResultComp";
import { errorImg } from "../../utils/Imagepath";
import Modal from "../../utils/Modal";
import dayjs from 'dayjs';
import { Affix, Button, Col, DatePicker, Form, Popover, Row, Select } from 'antd';
import './SearchResult.scss';
import moment from 'moment/moment';


function SearchResult() {
    const [datas, setDatas] = useState([])
    const [DataFound, setDataFound] = useState(false);
    const [dataShow, setDataShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const { RangePicker } = DatePicker;
    const Option = Select;
    const location = useLocation();
    let HotalRoomData = location?.state?.state;
    const [show, setShow] = useState(null);
    const dateFormat = 'MMM DD YYYY';


    const dayjs = require('dayjs');
    const currentDate = dayjs();
    const nextDayDate = currentDate.add(1, 'day');
    const currentFormattedDate = currentDate.format(dateFormat);
    const nextDayFormattedDate = nextDayDate.format(dateFormat);



    let startDate, endDate, selectAdult, selectChild, selectRoom, childAges, getuserStatus;
    startDate = JSON.parse(localStorage.getItem('startDate'));
    endDate = JSON.parse(localStorage.getItem('endDate'));
    selectAdult = JSON.parse(localStorage.getItem('selectAdults'));
    selectChild = JSON.parse(localStorage.getItem('selectChild'));
    selectRoom = JSON.parse(localStorage.getItem('selectRoom'));
    childAges = JSON.parse(localStorage.getItem('childAges'));
    getuserStatus = JSON.parse(localStorage.getItem('userStatus'));
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    const [AddSelectAdult, setAddSelectAdult] = useState(selectAdult);
    const [selectRooms, setSelectRooms] = useState(selectRoom)
    const [selectchildren, setSelectchildren] = useState(selectChild);
    const [childAgesChange, setChildAgesChange] = useState(Array.isArray(childAges) ? childAges : []);
    const [filterFields, setFilterFields] = useState({
        start_date: null,
        end_date: null,
    });

    const handleChange = (range) => {
        let dateFrom = range[0].format("YYYY-MM-DD");
        let dateTo = range[1].format("YYYY-MM-DD");
        setFilterFields((prevState) => ({
            ...prevState,
            start_date: dateFrom,
            end_date: dateTo,
        }));
    };

    let currentOnChageStartDate = filterFields?.start_date;
    let currentOnChageEndDate = filterFields.end_date;

    const fetchAllHotalRooms = () => {
        const reqBody = {
            fromDate: currentOnChageStartDate ? currentOnChageStartDate : dayjs(startDate).format("YYYY-MM-DD"),
            endDate: currentOnChageEndDate ? currentOnChageEndDate : dayjs(endDate).format("YYYY-MM-DD")
        };

        setLoading(true)
        setDataShow(true);
        fetchAllData(reqBody)
            .then((res) => {
                setLoading(false)
                if (res && res.length === 0) {
                    setLoading(false)
                    setDataFound(true)
                    setDatas([])
                    setTimeout(() => {
                        setDataFound(false)
                    }, 3000);
                } else {
                    setLoading(false)
                    localStorage.setItem('startDate', JSON.stringify(currentOnChageStartDate ? currentOnChageStartDate : startDate));
                    localStorage.setItem('endDate', JSON.stringify(currentOnChageEndDate ? currentOnChageEndDate : endDate));
                    localStorage.setItem('selectAdults', JSON.stringify(AddSelectAdult ? AddSelectAdult : selectAdult));
                    localStorage.setItem('selectChild', JSON.stringify(selectchildren ? selectchildren : selectChild));
                    localStorage.setItem('selectRoom', JSON.stringify(selectRooms ? selectRooms : selectRoom));
                    localStorage.setItem('childAges', JSON.stringify(childAgesChange));
                    setDatas(res);
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("err", err);
            });
    };


    const disabledPastDate = (current) => {
        const startOfSelectedDate = moment(currentOnChageStartDate).startOf('day');
        const startOfToday = moment().startOf('day');
        return current && (current.isSame(startOfSelectedDate, 'day') || current.isBefore(startOfToday, 'day'));
    };

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const addAdults = () => {
        if (AddSelectAdult < 10) {
            localStorage.setItem('selectAdults', JSON.stringify(AddSelectAdult + 1));
            setAddSelectAdult(AddSelectAdult + 1);
        }
    }

    const removeAdults = () => {
        if (AddSelectAdult > 1) {
            setAddSelectAdult(prevAdults => prevAdults - 1);
            localStorage.setItem('selectAdults', JSON.stringify(AddSelectAdult - 1));
            setSelectchildren(prevChildren => {
                return prevChildren > 0 ? prevChildren - 1 : 0;
            });
            localStorage.setItem('selectChild', JSON.stringify(selectchildren - 1));
        }
    };

    const addRooom = () => {
        if (selectRooms < 10) {
            localStorage.setItem('selectRoom', JSON.stringify(selectRooms + 1));
            setSelectRooms(selectRooms + 1);
        }
    }

    const removeRoom = () => {
        if (selectRooms > 0) {
            localStorage.setItem('selectRoom', JSON.stringify(selectRooms - 1));
            setSelectRooms(selectRooms - 1);
        }
    }

    const addChildern = () => {
        if (selectchildren < AddSelectAdult) {
            localStorage.setItem('selectChild', JSON.stringify(selectchildren + 1));
            setSelectchildren(selectchildren + 1);
        }
    }

    const removeChildern = () => {
        if (selectchildren > 0) {
            localStorage.setItem('selectChild', JSON.stringify(selectchildren - 1));
            setSelectchildren(prevSelectChildren => prevSelectChildren - 1);
            setChildAgesChange(prevChildAges => {
                const updatedAges = prevChildAges.slice(0, -1);
                localStorage.setItem('childAges', JSON.stringify(updatedAges));
                return updatedAges;
            });
        }
    }


    // const onSelectChildAge = (value, index) => {
    //     setChildAgesChange(prevChildAges => {
    //         const updatedAges = [...prevChildAges];
    //         updatedAges[index] = value;
    //         localStorage.setItem('childAges', JSON.stringify(updatedAges)); 
    //         return updatedAges; 
    //     });
    // }


    useEffect(() => {
        const storedChildAges = JSON.parse(localStorage.getItem('childAges'));
        if (storedChildAges) {
            setChildAgesChange(storedChildAges);
        }
    }, []);

    const onSelectChildAge = (value, index) => {
        setChildAgesChange(prevChildAges => {
            const updatedAges = [...prevChildAges];
            updatedAges[index] = value;
            localStorage.setItem('childAges', JSON.stringify(updatedAges));
            return updatedAges;
        });
    };

    const applyChanges = () => {
        formRef.current.validateFields()
            .then(() => {
                setOpen(false);
            })
            .catch((error) => {
                console.error('Validation error:', error);
            });
    };

    const [roomCategoryList, setRoomCategoryList] = useState([]);

    const locationData = useLocation();

    console.log("locationData", locationData.state.room_Code);
    let roomTypeData = locationData.state.room_Code;
    useEffect(() => {
        const reqBody = {
            fromDate: startDate,
            endDate: endDate
        };
        fetchAllData(reqBody)
            .then((res) => {
                setRoomCategoryList(res);
            })
            .catch((err) => {
                console.log("err", err);
            })
    }, [])

    console.log("roomCategoryList", roomCategoryList)


    // const roomTypes = ['double-room', 'single-bed-room', 'super-deluxe-room'];



    function filterByRoomType(roomType) {
        return roomCategoryList.filter(room => room.room_Code === roomType);
    }

    const doubleRoomData = filterByRoomType(roomTypeData);

    const content = (
        <>
            <Form ref={formRef} className='popup-form'>
                <div className='popup-inner'>
                    <div className='select-adult mb-3'>
                        <h6>Adults</h6>
                        <span className='selection'>
                            <Button className='me-3' onClick={removeAdults}>-</Button>
                            <span className='me-3'>{AddSelectAdult}</span>
                            <Button onClick={addAdults}>+</Button>
                        </span>
                    </div>

                    <div className='select-room  mb-3'>
                        <h6>Room</h6>
                        <span className='selection'>
                            <Button className='me-3' onClick={removeRoom}>-</Button>
                            <span className='me-3'>{selectRooms}</span>
                            <Button onClick={addRooom}>+</Button>
                        </span>
                    </div>

                    <div className='select-child  mb-3'>
                        <h6>Children</h6>

                        <span className='selection'>
                            <Button className='me-3' onClick={removeChildern}>-</Button>
                            <span className='me-3'>{selectchildren}</span>
                            <Button onClick={addChildern}>+</Button>
                        </span>
                    </div>
                    <span className='mx-5'>
                        <Row>
                            {[...Array(selectchildren)].map((_, index) => (
                                <Col span={12} key={index}>
                                    <div className='select-amount'>
                                        <Form.Item
                                            name={`childAge-${index}`}
                                            initialValue={childAgesChange[index]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select child age',
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={(value) => onSelectChildAge(value, index)}
                                                placeholder="Select Child age"
                                                style={{ width: '100%' }}
                                                className='mb-3 px-2'
                                            >
                                                {[...Array(12)].map((_, age) => (
                                                    <Option key={age} value={age + 1}>
                                                        {age + 1} years old
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </span>

                </div>
                <div className='popup-footer'>
                    <Button onClick={applyChanges} className='apply-btn'>Apply</Button>
                </div>
            </Form>
        </>
    );

    return (

        <>
            <section className="main shadow-sm customize-date">
                <div className="container">
                    <div className="search-bar">

                        <Form layout="vertical">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">

                                    <div className='row'>
                                        <div className='col-6'>
                                            <label for="">Check-In Date</label>

                                        </div>
                                        <div className='col-6'>
                                            <label for="">Check-Out Date</label>
                                        </div>
                                        <div className='col-12'>
                                            {startDate ? (
                                                <Form.Item>
                                                    <RangePicker
                                                        defaultValue={[dayjs(startDate), dayjs(endDate)]}
                                                        onChange={handleChange}
                                                        format={dateFormat}
                                                        disabledDate={disabledPastDate}
                                                        className="w-100 h-25"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <Form.Item label="Check-in Date - Check-out Date">
                                                    <RangePicker
                                                        onChange={handleChange}
                                                        format={dateFormat}
                                                        disabledDate={disabledPastDate}
                                                        className="w-100 h-25"
                                                    />
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 col-lg-4">
                                    <label for="">Room | Adult | Child</label>
                                    <Popover
                                        content={content}
                                        placement="bottom"
                                        trigger="click"
                                        open={open}
                                        onOpenChange={handleOpenChange}
                                    >
                                        <div className="select-acr" style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 500, fontFamily: 'Barlow' }}>

                                            {selectAdult} {selectAdult === 1 ? "Adult" : "Adults"}, {""}
                                            {selectChild} {selectChild === 1 ? "child" : "children"}, {""}
                                            {selectRoom} {selectRoom === 1 ? "Room" : "Rooms"}
                                            <i className='bx bx-chevron-down'></i>
                                        </div>
                                    </Popover>
                                </div>
                                <div className="col-sm-4 col-lg-2">
                                    <button
                                        className="modify-btn"
                                        onClick={fetchAllHotalRooms}
                                        style={{ marginTop: '40px' }}
                                    >
                                        <i className='bx bx-search-alt-2 mr-10' ></i> &nbsp;
                                        Search
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div >
                </div >
            </section >

            {/* {!getuserStatus &&
                <Affix>
                    <div className="container">
                        <div className="strip typewriter text">
                            <h3>Save an average of 10%  when you're signed in </h3>
                        </div>
                    </div>
                </Affix>
            } */}

            <section className="searchResult">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {dataShow &&
                                <SearchResultComp
                                    data={datas}
                                    loading={loading}
                                    AddSelectAdult={AddSelectAdult}
                                    selectchildren={selectchildren}
                                    setShow={setShow}
                                    show={show}

                                />}
                            {!dataShow &&
                                <SearchResultComp
                                    data={HotalRoomData}
                                    loading={loading}
                                    AddSelectAdult={AddSelectAdult}
                                    selectchildren={selectchildren}
                                    setShow={setShow}
                                    show={show}
                                />}

                            {/* home page */}

                            <SearchResultComp
                                data={doubleRoomData}
                                loading={loading}
                                AddSelectAdult={AddSelectAdult}
                                selectchildren={selectchildren}
                                setShow={setShow}
                                show={show}
                            />


                            {DataFound &&
                                <Modal
                                    image={errorImg}
                                    messageText="No rooms available. Consider searching for different dates ."
                                />}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SearchResult;