import React from 'react';

function PrivacyPolicy() {
    return (
        <>
            <div className='container'>
                <div className="row">

                    <div className="col-lg-12">
                        <div className="policy-main shadow-sm mb-5">
                            <div className="policy-header position-relative">
                                <h3 className="heading">Privacy Policy for Superb Hotel</h3>
                                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5" style={{ left: '0' }}>
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" style={{ height: '7vh', minHeight: '50px' }}>
                                        <defs>
                                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                        </defs>
                                        <g className="moving-waves">
                                            <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="policy-body p-4">
                                <p>Welcome to superbhotel.in, At Superb Hotel, we are committed to safeguarding the privacy of our users. This Privacy Policy outlines how we collect, use, and protect the information shared with us.</p>

                                <ol>
                                    <li><span>General</span>
                                        <p>We are concerned with the protection of your privacy and will comply with all applicable Indian data protection legislation.</p>
                                    </li>
                                    <li><span>Collection of Information</span>
                                        <p>When you visit our website, no personal details are required. Simply browsing the site allows you to remain anonymous. However, upon registering with us for any service, booking, communication, or interaction on our website, you consent to the use of your personal information.</p>
                                    </li>
                                    <li><span>Cookies Policy</span>
                                        <p>We use cookies to enhance your experience, providing personalized information based on preferences and activities. This information is used to improve our services, and no personally identifiable information is collected during this process.</p>
                                    </li>
                                    <li><span>Personal Information Collected</span>
                                        <p>When making bookings or using our services, we may collect the following personal information:</p>
                                        <ul>
                                            <li>Client’s Name, gender, addresses (optional), age, marital status, contact number, Email ID, etc.</li>
                                            <li>Service Usage: URLs of visited websites and requested downloads.</li>
                                            <li>Booking Information: Details provided to vendors (hotels, date, destination, and other transport services) for bookings.</li>
                                        </ul>
                                    </li>
                                    <li><span>Use of Personal Information</span>
                                        <p>The information collected is used for the following purposes:</p>
                                        <ul>
                                            <li>Providing services to clients.</li>
                                            <li>Improving our website and services.</li>
                                            <li>Communication about special deals, offers, and promotions.</li>
                                            <li>Booking purposes.</li>
                                            <li>Customer service and feedback.</li>
                                        </ul>
                                    </li>
                                    <li><span>Intimation of Promotional Offers</span>
                                        <p>Users may receive updates about special deals, travel packages, and promotions via email or SMS. This service is optional, and users can unsubscribe at any time.</p>
                                    </li>
                                    <li><span>Registration as Member</span>
                                        <p>Registering as a member involves providing personal information for purposes such as booking, recognition, customer service, and improvements to products and services.</p>
                                    </li>
                                    <li><span>Evaluation and Feedback</span>
                                        <p>Periodic evaluations and surveys may be conducted for feedback and improvement. Participation is optional, and participant identities remain anonymous.</p>
                                    </li>
                                    <li><span>Protecting Sensitive Information</span>
                                        <p className='text-success'>To ensure the maximum security of your data, all payments made on our website are processed by a reputable, RBI-certified payment gateway - CCAvenue. We do not store any kind of banking details when you make payment on our website.</p>

                                        <span>Session Data Logging</span>
                                        <p>Session data, including browser language, IP address, OS, and user activities is logged for server evaluation and system improvement. This information is not personally identifiable.</p>
                                    </li>
                                    <li><span>Security Measures</span>
                                        <p>We use advanced technology to secure user information and continuously upgrade data security measures to ensure the confidentiality and safety of our client's information.</p>
                                    </li>
                                    <li><span>Children's Privacy</span>
                                        <p>Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from our servers.</p>
                                        <p>If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require Your parent's consent before We collect and use that information.</p>
                                    </li>
                                    <li>
                                        <span>Links to Other Websites</span>
                                        <p>Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third-party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
                                    </li>
                                    <li>
                                        <span>Changes to Privacy Policy</span>
                                        <p>Superb Hotel reserves the right to change our Privacy Policy at any time due to technological advancements or unforeseen circumstances. Regularly check our website for updates.
                                            <span className='text-success'>Changes are effective immediately after their posting on the Website. Your continued use of the Website constitutes your agreement to these changes.</span>
                                        </p>
                                    </li>
                                </ol>

                                <div className='contactDetail'>
                                    <span>Contact Us</span>
                                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>

                                    <p><span>By email :</span> reservation@superbhotel.in</p>
                                    <p><span>By visiting this page on our website :</span> http://www.superbhotel.in/contact</p>
                                    <p><span>By phone number :</span> 0404 855 1669</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;