import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Carousel, Image, Select, notification, Button } from "antd";
import BookNowBottamComp from './BookNowBottamComp';
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';
import { gallery12, gallery13, gallery14, gallery15, gallery16 } from '../../utils/Imagepath';
import { getRoomTypeImage } from '../../services/EndPoint';

function SearchResultComp(props) {
    const { data, loading, selectAdults, selectchildren, setShow, show } = props;
    const [roomCounts, setRoomCounts] = useState({})
    const [roomCountsAdulta, setRoomAddAdulta] = useState([])
    const [api, contextHolder] = notification.useNotification();


    const navigate = useNavigate();
    const selectAdult = JSON.parse(localStorage.getItem('selectAdults'));
    const selectChild = JSON.parse(localStorage.getItem('selectChild'));
    const { Option } = Select;
    const [roomCodeSend, setRoomCodeSend] = useState([])
    const [roomRatePlanCode, setRoomRatePlanCode] = useState([])
    const [showCss, setHideCss] = useState(null)
    const [getImgGallery, setGetImgGallery] = useState([]);


    useEffect(() => {
        getRoomTypeImage()
            .then((galleryData) => {
                setGetImgGallery(galleryData?.data?.data);
                console.log("galleryData", galleryData?.data?.data);
            })
            .catch((error) => {
                console.error('Error fetching gallery images:', error);
            });
    }, []);





    const onSelectRoom = (value, i, result) => {
        const selectedValue = value;
        const allNonZero = Object.values(roomCounts).every(value => value !== "0");
        const isZeroSelected = selectedValue === 0;
        if (isZeroSelected) {
            setShow(false);
        } else if (allNonZero) {
            setShow(true);
        } else {
            // Handle other cases if needed
        }

        setRoomCodeSend(prevRoom => ({
            ...prevRoom,
            [i]: result.room_Code
        }))
        setRoomRatePlanCode(prevPlan => ({
            ...prevPlan,
            [i]: result.rateplan_Code
        }))
        setRoomCounts(prevCounts => ({
            ...prevCounts,
            [i]: selectedValue
        }));

        setRoomAddAdulta(selectedValue)

    }

    // const getTotalAmount = () => {
    //     let totalAmount = 0;
    //     for (const i in roomCounts) {
    //         totalAmount += props.data[i].rate * roomCounts[i];
    //     }
    //     return totalAmount;
    // }

    const getTotalAmount = () => {
        let totalAmount = 0;
        for (const i in roomCounts) {
            if (roomCounts[i] && roomCounts[i] !== '0') {
                totalAmount += props.data[i].rate * roomCounts[i];
            }
        }
        return totalAmount;
    };


    const totalSelectRoom = Object.keys(roomCounts).reduce((total, index) => {
        if (roomCounts[index] > 0) {
            return total + roomCounts[index];
        }
        return total;
    }, 0);

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Warning',
            description:
                'You will have to add or choose another room, as a maximum of 2 adults can stay in one room.',
            placement: "top"
        });
    };

    const selectAdultss = JSON.parse(localStorage.getItem('selectAdults'));

    const bookNowAdultsAndRoom = () => {
        if (totalSelectRoom * 2 >= selectAdultss && totalSelectRoom * 2 >= selectAdultss) {
            navigate("/book-now", {
                state: {
                    roomCounts: roomCounts,
                    totalAmount: getTotalAmount(),
                    totalSelectRoom: totalSelectRoom,
                    roomCodeSend: roomCodeSend,
                    roomRatePlanCode: roomRatePlanCode
                }
            });
        } else if (selectAdultss <= totalSelectRoom && selectAdultss <= totalSelectRoom) {
            openNotificationWithIcon('warning');
        } else {
            openNotificationWithIcon('warning');
        }
    };

    const [isActive, setActive] = useState("false");


    const imgGallery = [
        {
            id: 1,
            imgUrl: gallery13
        },
        {
            id: 2,
            imgUrl: gallery14
        },
        {
            id: 3,
            imgUrl: gallery15
        },
        {
            id: 4,
            imgUrl: gallery16
        },

    ];

    const arrowStyle = {
        fontSize: '24px',
        color: '#727171;',
        position: 'absolute',
        top: '50%',
        zIndex: '1',
    };

    const Arrow = ({ type, onClick }) => {
        if (type === 'left') {
            return <LeftOutlined style={{ ...arrowStyle, left: '6px' }} onClick={onClick} />;
        } else if (type === 'right') {
            return <RightOutlined style={{ ...arrowStyle, right: '6px' }} onClick={onClick} />;
        }
        return null;
    };

    return (
        <>
            {contextHolder}
            <div className={showCss ? "inner-card-12 d-flex" : "inner-card-12"} >
                <Card loading={loading} className='border-0'>
                    {props?.data?.map((result, i) => {
                        const amount = roomCounts[i] ? result.rate * roomCounts[i] : result.rate;

                        return (
                            <div className="card" key={i}>
                                <div className="row">
                                    <div className='col-md-9 col-lg-10'>
                                        <div className='row'>

                                            <div className="col-md-5 pe-lg-0">
                                                <div className="image-gallery">

                                                    <div className="main-image ms-3 mt-3">
                                                        <Carousel
                                                            arrows
                                                            dots
                                                            prevArrow={<Arrow type="left" />}
                                                            nextArrow={<Arrow type="right" />}
                                                            infinite={true}
                                                            effect='fade'
                                                            className="custom-carousel"
                                                        >

                                                            {/* {getImgGallery?.map(item => (
                                                                <div key={item.id}>
                                                                    <Image src={item.image_url} preview={false} style={{ width: '100%', height: 'auto' }} />
                                                                </div>
                                                            ))} */}
                                                            {getImgGallery.filter(img => (
                                                                (img.name === 'deluxe' && result.room_Code === 'super-deluxe-room') ||
                                                                (img.name === 'double' && result.room_Code === 'double-room') ||
                                                                (img.name === 'single' && result.room_Code === 'single-bed-room')
                                                            )).map((filteredImg, filteredIndex) => (
                                                                <>
                                                                    {console.log("objectparveen", filteredImg)}
                                                                    <div key={filteredIndex}>
                                                                        <Image src={filteredImg?.image_url} preview={false} style={{ width: '100%', height: 'auto' }} />
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </Carousel>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="content col-md-7 pe-lg-0">
                                                <div className='room-title-and-date mb-2 pt-3'>
                                                    <h3>{result?.room_Code.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}  <span>(Rate Plan : <span> {result?.rateplan_Code}</span>)</span></h3>
                                                    <span>Check-In Date : {result?.startDate}</span> | <span>Check-Out Date : {result?.endDate}</span>
                                                </div>

                                                <div className="amenities">
                                                    <p>Amenities</p>
                                                    <ul>
                                                        <li><img src="assets/images/room-service.png" alt="icon" /> 24*7 Room Service</li>
                                                        <li><img src="assets/images/restaurant.png" alt="icon" />Restaurant</li>
                                                        <li><img src="assets/images/internet.png" alt="icon" />Internet</li>
                                                        <li><img src="assets/images/air-conditioner.png" alt="icon" />Air Conditioning</li>
                                                    </ul>
                                                </div>

                                                <div className="facilities pb-3">
                                                    <p>Basic Facilities</p>
                                                    <ul>
                                                        <li>{result?.room_Code.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}  with Free Wi-Fi,</li>
                                                        <li>include a flat-screen TV,</li>
                                                        <li>Dry Cleaning Service,</li>
                                                        <li>Restaurant with Dining Menu,</li>
                                                        <li>Veg/Non-Veg Food is Allowed,</li>
                                                        <li>Paid Airport Transfer,</li>
                                                        <li>Paid Pickup & Drop</li>
                                                    </ul>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-2 d-flex justify-content-center align-items-end">

                                        <div className="rate-book-info">
                                            {roomCounts[i] === "0" ?
                                                <span className="mb-4 sold-out" style={{ background: "#FF0000" }}>
                                                    Sold out
                                                </span> :
                                                <div className="rate"><i className='bx bx-rupee'></i>{amount}/-</div>
                                            }

                                            <div className='select-amount'>
                                                <Select
                                                    onChange={(value) => onSelectRoom(value, i, result)}
                                                    defaultValue={null}
                                                    placeholder="Select Room"
                                                    style={{ width: '100%' }}
                                                    className='mb-3'
                                                    allowClear
                                                >
                                                    {/* <Option value="0">clear</Option> */}
                                                    {result?.available && result.rate && [...Array(result.available)].map((_, index) => (
                                                        <Option key={index + 1} value={index + 1}>
                                                            {index + 1} - (<span>&#8377; {result.rate * (index + 1)})</span>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {show && (
                        <div className="book-room-info d-block d-lg-none">
                            <div className="book-room-amount">
                                <div className="room-info">

                                    <h6>
                                        {Object.keys(roomCounts).map((index, i, arr) => {
                                            const roomCount = roomCounts[index];
                                            const roomCode = props.data[index]?.room_Code;
                                            const capitalizedRoomCode = roomCode ? roomCode.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '';

                                            if (roomCount > 0) {

                                                return (
                                                    <React.Fragment key={`${index}`}>
                                                        {roomCount} {capitalizedRoomCode} Room{i !== arr.length - 1 ? ', ' : ''}
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </h6>

                                </div>


                                <div className="room-info">
                                    {selectAdults} {selectAdults === 1 ? "Adult" : "Adults"}
                                    {/* <span>{selectAdults ? selectAdults : selectAdult} Adults</span> */}
                                </div>

                                {selectChild !== 0 && (
                                    <div className="room-info">
                                        <h6>{selectChild} {selectChild === 1 ? "child" : "children"}</h6>
                                    </div>
                                )}

                                <div className="room-info">
                                    <h6 className='total-amm'>Total Amount <br /><span className="roomFor">For    ,  , {totalSelectRoom} {totalSelectRoom === 1 ? "Room" : "Rooms"}</span></h6>
                                    <span className="total-rate">₹ {getTotalAmount()}</span>
                                    <button className="btn" onClick={bookNowAdultsAndRoom}>Book Now</button>
                                </div>

                            </div>
                        </div>
                    )}

                </Card>

                <div className='d-lg-block d-md-block d-none'>

                    <div className={`${isActive ? "hotel_details_show" : "hotel_details"}`}>

                        {/* <a onClick={() => setShow(!show)} className='show_hotel_details'>
                            <i className='bx bx-chevron-right'></i>
                        </a> */}

                        {show && getTotalAmount() !== 0 && (
                            <>
                                <BookNowBottamComp
                                    roomCounts={roomCounts}
                                    data={data}
                                    setHideCss={setHideCss}
                                    setShow={setShow}
                                    getTotalAmount={getTotalAmount}
                                    bookNowAdultsAndRoom={bookNowAdultsAndRoom}
                                    selectchildren={selectchildren}
                                    selectChild={selectChild}
                                    selectAdult={selectAdult}
                                    selectAdults={selectAdults}
                                    totalSelectRoom={totalSelectRoom}
                                />
                            </>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}



export default SearchResultComp