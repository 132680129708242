import React from "react";
import '../../Location-and-surroundings/locationAndsurroundings.scss';

function QutubShahiTomb(){
    return(
        <>
        <div className="main-banner" style={{background: 'url(assets/images/jagannathtemple.jpg)', backgroundSize: 'cover'}}>
     <div className="banner-caption">
         <span>Hyderabad</span>
         <h1>Qutub Shahi Tomb </h1>
     </div>
 </div>
 <div className="container">
     <div className="about-destination">
         <div className="heading">
             <h3>A Tour From The Superb Hotels to Qutub Shahi Tombs  </h3>
         </div>
         <div className="row mb-3">
             <div className="col-md-12">
                 <p className="text">Located in the Ibrahim Bagh, the Qutb Shahi Tombs includes the mosques and tombs run by multiple lords of the Qutub Shahi line. The architecture of this tomb has a glimpse of Indian and Persian architectural styles. You can observe the impacts of Pathan, Deccan, Persian as well as Hindu styles on the tomb’s structure. </p>
                 <p className="text">The entry point and corridors of the Qutub Shahi tombs show an Indo-Saracenic style of architecture, whereas decorative minarets and parapets represent Islamic architecture. Plus, the false ceiling, central pillar, and two-tiered terraces form a mandatory portion of the structure and have also been fancy decorated. All these seven tombs were planned and constructed by Qutub Shahis. These were restored by Salar Jung III, Mir Yousuf Ali Khan around the 19th century. </p>
             </div>
         </div>
 
         <div className="heading">
             <h3>Also, Explore the Nearby Places of the Qutub Shahi Tombs </h3>
         </div>
         <p>During your visit to Qutub Shahi Tombs, you can also explore some nearby places as listed below:</p>
         <div className="row mt-5 mb-3">
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/tank-bund.png" alt="Tank-Bund" />
                     <div className="title">
                         <h5>Tank Bund</h5>
                     </div>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/NTR-Garden.png" alt="NTR-Garden" />
                     <div className="title">
                         <h5>NTR Gardens</h5>
                     </div>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/rath-yatra.jpg" alt="rath-yatra" />
                     <div className="title">
                         <h5>Golconda Fort</h5>
                     </div>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/ganesh-chaturthi.jpg" alt="ganesh-chaturthi" />
                     <div className="title">
                         <h5>Nehru Zoological Park</h5>
                     </div>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/chilkur-balaji.jpg" alt="chilkur-balaji" />
                     <div className="title">
                         <h5>Chilkur Balaji Temple</h5>
                     </div>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="card">
                     <img src="assets/images/taramati-baradari.jpg" alt="taramati-baradari" />
                     <div className="title">
                         <h5>Taramati Baradari</h5>
                     </div>
                 </div>
             </div>
         </div>
        
     </div>
 </div>
 <div className="how-to-reach">
     <div className="container">
         <div className="box">
             <div className="heading">
                 <h3>Mediums to Reach</h3>
             </div>
             <p>The total distance between Qutb Shahi Tombs from Hyderabad Airport is approx. 28 Km and would not take more than 40 minutes to reach.</p>
             <p>Furthermore, if you stay at The Superb Hotels, then you can use a cab or taxi to reach there as the tomb is just a distance of 10.4 Km. </p>
         </div>
     </div>
 </div>
 <div className="additional-details">
     <div className="container">
         <div className="heading">
             <h3>Additional Details</h3>
         </div>
         <div className="row">
             <div className="col-md-3">
                 <div className="box">
                     <div className="icon">
                         <i className='bx bx-time-five'></i>
                     </div>
                     <h5>Timings</h5>
                     <span>
                     Between 9.30 AM to 6.30 PM
                     </span>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="box">
                     <div className="icon">
                         <i className='bx bx-rupee'></i>
                     </div>
                     <h5>Entry Fee</h5>
                     <span>INR 10/- for Adults </span>
                     <span>INR 5/- for Children</span>
                     <span>INR 20/- for Camera</span>
                     <span>INR 140/- for Foreigners</span>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="box">
                     <div className="icon">
                         <i className='bx bx-rupee'></i>
                     </div>
                     <h5>Best Season to Visit</h5>
                     <span>Between November to March</span>
                 </div>
             </div>
             <div className="col-md-3">
                 <div className="box">
                     <div className="icon">
                         <i className='bx bx-camera'></i>
                     </div>
                     <h5>Facilities</h5>
                     <span>Food Stalls and Washrooms</span>
                 </div>
             </div>
         </div>
     </div>
 </div>
        </>
     )
}

export default QutubShahiTomb;