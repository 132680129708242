


import React from 'react';

const styleLoader = {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: 'blur(2px)',
    background: "#00000085",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
};

const styleLoaders = {
    width: "380px",
    padding: "25px 15px",
    textAlign: "center",
    background: '#fff',
    borderRadius: "25px",
    boxShadow: "0px 0px 12px 0px #0000002d",
}

const Modal = (props) => {

    return (
        <>
            <div style={styleLoader}>
                <div style={styleLoaders}>
                    <img src={props?.image} alt='' style={{ width: "150px" }} />
                    <p style={{ color: "#F44336" }}><b>{props?.messageText}</b></p>
                </div>
            </div >
        </>
    );
}

export default Modal;