import instance from "./axios";

const uri = {
    fetchList: "/Values/show_rooms",
    fetchAllData: "/Home/RoomDetails",
    saveGuestBookingdetails: "/Home/SaveGuestBookingdetaisl",
    userloginSignUp: "LoginUser/SignupLogin",
    otpEnteredUser: "/LoginUser/VerifyOTP",
    createProfile: "/LoginUser/UpdateUserProfile",
    Updatebillinginformation: "/LoginUser/Updatebillinginformation",
    userDetail: "LoginUser/GetUserDetails",
    mailVerifyFunction: "/LoginUser/SendEmailOtp",
    otpConfirmationEmail: "/LoginUser/VerifyOTPEmail",
    contectUsComment: "/Home/ContactInfo",
    bookinghotelConform: "/Home/SaveGuestBookingdetails",
    getBookingsForUser: "/HotelBooking/GetBookingsForUser",
    getBookingsForUser: "/HotelBooking/GetBookingsForUser",
    CheckPaymentAmount: "Home/CheckPaymentAmount",
    galleryImage: "https://mysuperb.app/v1/imagelist/gallery",
    roomTypeImage: "https://mysuperb.app/v1/imagelist/room"
};

export const fetchCategoryList = async (reqBody) => {
    return await instance.get(uri.fetchList, reqBody);
};


export const fetchAllData = async (reqBody) => {
    try {
        const response = await instance.get(uri.fetchAllData, { params: reqBody });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const saveGuestBookingdetails = async (payload) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return await instance.post(uri.saveGuestBookingdetails, payload,
        { headers: headers, });

};

// export const userloginSignUp = async (payload) => {
//     return await instance.post(uri.userloginSignUp, payload);
// };

// export const userloginSignUp = async (payload) => {
//     return await instance.post(uri.userloginSignUp, payload, {
//     });
// };

export const userloginSignUp = async (payload) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return await instance.post(uri.userloginSignUp, payload,
        { headers: headers, });

};

export const userloginSignUpEmail = async (payload) => {
    return await instance.post(uri.userloginSignUpEmail, payload, {
    });
};

export const otpConfirmationUser = async (payload) => {
    return await instance.post(uri.otpEnteredUser, payload);

};

export const createProfileUser = async (payload) => {
    return await instance.post(uri.createProfile, payload);
};

export const Updatebillinginformation = async (payload) => {
    return await instance.post(uri.Updatebillinginformation, payload);
};

export const getUserDetails = async (id) => {
    return await instance.get(uri.userDetail + "/" + id);
};

export const mailVerifyFunction = async (payload) => {
    return await instance.post(uri.mailVerifyFunction, payload);
};

export const otpConfirmationEmail = async (authenticateOtp) => {
    return await instance.post(uri.otpConfirmationEmail, authenticateOtp);
};

export const contectUsComment = async (payload) => {
    return await instance.post(uri.contectUsComment, payload);
};

export const bookinghotelConform = async (payload) => {
    return await instance.post(uri.bookinghotelConform, payload);
};

export const getBookingsForUser = async (payload) => {
    try {
        const response = await instance.get(uri.getBookingsForUser, { params: payload });
        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        throw error;
    }
};

export const CheckPaymentAmount = async (payload) => {
    return await instance.post(uri.CheckPaymentAmount, payload);
};

export const galleryImage = async () => {
    return await instance.get(uri.galleryImage);
};

export const getRoomTypeImage = async () => {
    return await instance.get(uri.roomTypeImage);
};