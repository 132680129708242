import axios from "axios";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

const instance = axios.create({
    baseURL: BASE_URL,

    // Lets keep a check as default is 0 millisecond i.e. never
    // Note: timeout is only for server response not network i.e. server reachability
    timeout: 100000,

    // Lets keep a check as default bytes- 2k
    maxContentLength: 1000,

    // Lets keep a check as default 5 seems high
    maxRedirects: 2,

})

instance.interceptors.request.use((req) => {
    return req;
});



export default instance;