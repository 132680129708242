import React, { useEffect, useState } from 'react'
import '../Home/HotelSearchEngine/HotelSearchEngine.scss';
import { useLocation } from 'react-router-dom';
import GuestDetailsPage from './GuestDetailsPage';
import LoaderComp from '../../utils/LoaderComp';
import { getCountryDialCode, getfilteredCountries } from '../../utils/CountryApi';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Modal, Select, Col, Row, Form, Input, Button, Checkbox, Affix } from 'antd';
import moment from 'moment';
import PrivacyPolicy from '../Hotel-Policy/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../Hotel-Policy/TermsAndConditions/TermsAndConditions';
import { bookinghotelConform, getUserDetails } from '../../services/EndPoint';


const BookNowRoom = () => {
    const [passwordHide, setPasswordHide] = useState(null)
    const [addExtraBad, setAddExtraBad] = useState(0)
    const [openGuestDetailsPage, setOpenGuestDetails] = useState(null);
    const [extraBedNumber, setExtraBedNumber] = useState("")
    const [loading, setLoading] = useState(false);
    const [payloadData, setPayloadData] = useState('')
    const [selectTitle, setSelectTitle] = useState('')
    const location = useLocation();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    let userId = JSON.parse(localStorage.getItem('userId'));
    const dateFormat = 'MMM DD YYYY';
    const Option = Select;
    const [form] = Form.useForm();

    let startDate, endDate, selectAdult, selectChild, selectRoom, childAges;
    startDate = JSON.parse(localStorage.getItem('startDate'));
    endDate = JSON.parse(localStorage.getItem('endDate'));
    startDate = dayjs(startDate).format(dateFormat);
    endDate = dayjs(endDate).format(dateFormat);
    selectAdult = JSON.parse(localStorage.getItem('selectAdults'));
    selectChild = JSON.parse(localStorage.getItem('selectChild'));
    selectRoom = JSON.parse(localStorage.getItem('selectRoom'));
    childAges = JSON.parse(localStorage.getItem('childAges'));

    const startDateFormatted = moment(startDate).format('YYYY-MM-DD');
    const endDateFormatted = moment(startDate).format('YYYY-MM-DD');
    const [searchQuery, setSearchQuery] = useState('India');
    const [valueSlectorHandel, setValueSelectHandel] = useState(false);
    const [selectedDialCode, setSelectedDialCode] = useState('');
    const filteredCountries = getfilteredCountries.filter(country =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const emailAddress = JSON.parse(localStorage.getItem('emailAddress'));

    useEffect(() => {
        if (userId) {
            getUserDetails(userId)
                .then((resp) => {
                    console.log("lastnameresp", resp)
                    form.setFieldsValue({
                        firstname: resp.data.firstName,
                        lastname: resp.data.lastName,
                        email: emailAddress,
                        contact: resp.data.phone_no,
                    });
                })
                .catch((error) => {
                    console.error("errrr", error);
                });
        }
    }, []);

    const searchHandler = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        setValueSelectHandel(true);
    };

    useEffect(() => {
        if (searchQuery === '') {
            setValueSelectHandel(false);
            setSelectedDialCode('');
        }
    }, [searchQuery]);


    const eventSelector = (country) => {
        console.log("item", country?.name)
        setValueSelectHandel(false);
        setSearchQuery(country?.name);
        if (country?.name === "India") {
            setPasswordHide(false);
            setSelectedDialCode("+91");
        } else {
            setPasswordHide(true);
            setSelectedDialCode(country?.dial_code);
        }
    }

    const [randomNumber, setRandomNumber] = useState(null);

    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 9000000000) + 10000;
    };

    const handleGenerateRandomNumber = () => {
        let newRandomNumber;
        do {
            newRandomNumber = generateRandomNumber();
        } while (newRandomNumber === randomNumber);

        setRandomNumber(newRandomNumber);
    };

    useEffect(() => {
        handleGenerateRandomNumber();
    }, [])


    const dataRomm = location.state.totalSelectRoom;
    const resultArray = Object.values(location.state?.roomCounts).map(value => value);
    const roomTypeSelect = location.state.roomCodeSend;
    const roomRatePlanCode = Object.values(location.state?.roomRatePlanCode)
    const resultRoomID = Object.values(roomRatePlanCode).join(' , ');
    const addExtraBadNumber = parseInt(addExtraBad);

    // let getuserStatus = JSON.parse(localStorage.getItem('userStatus'));

    // const calculateTotalAmount = () => {
    //     const baseAmount = location?.state?.totalAmount || 0;
    //     if (addExtraBad) {
    //         return baseAmount + 200 + addExtraBadNumber;
    //     } else {
    //         return baseAmount + 200;
    //     }
    // };


    const calculateTotalAmountSignin = () => {
        const baseAmount = location?.state?.totalAmount || 0;
        if (addExtraBad) {
            return (location.state.totalAmount - (10 / 100) * baseAmount) + 200 + addExtraBadNumber;
        } else {
            return location.state.totalAmount - (10 / 100) * baseAmount + 200;
        }
    };

    // const dataTotalAmount = getuserStatus ? calculateTotalAmountSignin() : calculateTotalAmount();
    const dataTotalAmount = calculateTotalAmountSignin();

    const apiUrl = 'https://api.superbhotel.in/Home/SaveGuestBookingdetails';

    const handleChange = (value) => {
        if (value) {
            const selectedIndex = options.findIndex(option => option.value === value);
            setExtraBedNumber(selectedIndex + 1)
            setAddExtraBad(value);
        } else {
            console.log("Clear selected value");
            setAddExtraBad(null);
        }
    };

    const options = [];
    for (let i = 1; i < location?.state?.totalSelectRoom + 1; i++) {
        options.push({
            bad: i.toString() + ' bed',
            value: (i * 500).toString(),
        });
    }

    const output = childAges?.map((age, index) => {
        return `${index + 1} children age ${age}`;
    }).join(', ');


    const onFinish = (values) => {
        const payload = {
            userId: userId,
            Guest_title: selectTitle ? selectTitle : "Mr",
            FirstName: values.firstname,
            LastName: values.lastname,
            Email: values.email,
            payment_id: "",
            booking_id: randomNumber,
            No_of_Adults: selectAdult,
            No_of_Childrens: selectChild,
            Extra_Beds: extraBedNumber,
            Mobile: selectedDialCode ? selectedDialCode + values?.contact : "+91" + values?.contact,
            Country: searchQuery,
            PassportNo: values.Passportid,
            GST: values.gstNumber,
            Remarks: "testing",
            privicyPlcy: values.checkPrivicy,
            TandC: true,
            RoomID: resultRoomID,
            Bookingfrom: startDateFormatted,
            BookingTo: endDateFormatted,
            Amount: dataTotalAmount,
            Rooms: location.state.totalSelectRoom,
        }
        bookinghotelConform(payload)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    // handleGenerateRandomNumber();
                    setPayloadData(payload);
                    setTimeout(() => {
                        setOpenGuestDetails(true);
                    }, 1000);
                    setLoading(false);
                } else {

                }
            })
            .catch(error => {
                console.log("err", error);
                setLoading(false);
                setOpenGuestDetails(false);
            });
    };

    return (
        <>
            <div className="bg-main"></div>
            <div className="booknow">
                {
                    openGuestDetailsPage &&
                    <GuestDetailsPage
                        setOpenGuestDetails={setOpenGuestDetails}
                        payloadData={payloadData}
                        roomTypeSelect={roomTypeSelect}
                        resultArray={resultArray}
                        openGuestDetailsPage={openGuestDetailsPage}
                        randomNumber={randomNumber}
                    />
                }

                {loading && <LoaderComp />}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Guest Details</h2>
                        </div>

                        <div className="col-lg-8 col-md-12">
                            <div className="room-detail">
                                <div className="date">
                                    <h5><span>Check-In Date :</span> <br /> {dayjs(startDate).format(dateFormat)}</h5>
                                    <div className="seprate-border"></div>
                                    <h5><span>Check-Out Date :</span>  <br />{dayjs(endDate).format(dateFormat)}</h5>

                                </div>
                                <div className="content">
                                    <div className="facilities">
                                        <h5 className='room-type'>
                                            {Object.keys(roomTypeSelect).map((key, index) => (
                                                resultArray[index] !== '0' && (
                                                    <React.Fragment key={index}>
                                                        {resultArray[index]}
                                                        &nbsp;
                                                        {roomTypeSelect[key]}
                                                        {index !== Object.keys(roomTypeSelect).length - 1 && <>&nbsp;,</>}
                                                        &nbsp;
                                                    </React.Fragment>
                                                )
                                            ))}
                                        </h5>

                                        <h6>{selectAdult} Adults{selectChild > 0 && `, ${selectChild} Children (${output})`}</h6>
                                        <ul>
                                            <li>
                                                {Object.entries(roomTypeSelect).map(([key, roomType], index) => (
                                                    resultArray[index] !== '0' && (
                                                        <span key={index}>{roomType.toLowerCase().split(' ')
                                                            .map((word) => word.charAt(0).toUpperCase() + word
                                                                .slice(1)).join(' ')},</span>
                                                    )
                                                ))}
                                                Rooms with Free Wi-Fi,</li>
                                            <li>include a flat-screen TV,</li>
                                            <li>Dry Cleaning Service,</li>
                                            <li>Restaurant with Dining Menu,</li>
                                            <li>Veg/Non-Veg Food is Allowed,</li>
                                            <li>Paid Airport Transfer,</li>
                                            <li>Paid Pickup & Drop </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="imptInfo mb-4 mb-md-0">
                                    <h6>Important Information</h6>
                                    <ul>
                                        <li>Unmarried couples are not allowed</li>
                                        <li>Passport, Aadhar, Driving License and Govt. ID are accepted as ID proof(s)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            {/* <Affix offsetTop={60}> */}
                            <div className="price-breakup">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <h5 className="amount" style={{ marginBottom: "20px" }}>Price Breakup</h5>
                                    <div className="room pb-2">
                                        <span>{dataRomm} Room </span>
                                        <div className="icon d-flex float-end">
                                            <i className='bx bx-rupee mt-1'></i>
                                            <span>{location.state.totalAmount} /-</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="extra-bed pb-2">
                                        <span>Add Extra Bed</span>
                                        <div className="icon d-flex float-end">
                                            <Select
                                                allowClear
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                onChange={handleChange}
                                            >
                                                {options.map(option => (
                                                    <Option key={option.value}
                                                        value={option.value}>{option.bad} ({option.value})
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>


                                    {/* {getuserStatus ?

                                    <>
                                        <hr />
                                        <div className="discount pb-2">
                                            <span>Total Discount 10%</span>
                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                <span>{(10 / 100) * location.state.totalAmount}/-</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="after-discount pb-2"><span>After Discount</span>
                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                <span>{location.state.totalAmount - (10 / 100) * location.state.totalAmount}/-</span>
                                            </div>
                                        </div>
                                        <hr />


                                        <div className="taxes pb-2"> <span>Hotals taxes</span>
                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                <span>200/-</span>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="t-amount pb-2">
                                            <span>Total amount</span>

                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                {addExtraBad ? (
                                                    <span>

                                                        {((location.state.totalAmount - (10 / 100) * location.state.totalAmount || 0)) + 200 + addExtraBadNumber}/-
                                                    </span>
                                                ) : (
                                                    <span>{(location.state.totalAmount - (10 / 100) * location.state.totalAmount || 0) + 200}/- </span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <>
                                        <div className="taxes pb-2"> <span>Hotals taxes</span>
                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                <span>200/-</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="t-amount pb-2">
                                            <span>Total amount</span>

                                            <div className="icon d-flex float-end">
                                                <i className='bx bx-rupee mt-1'></i>
                                                {addExtraBad ? (
                                                    <span>
                                                        {((location.state.totalAmount || 0)) + 200 + addExtraBadNumber}/-
                                                    </span>
                                                ) : (
                                                    <span>{(location.state.totalAmount || 0) + 200}/- </span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                } */}

                                    <hr />
                                    <div className="discount pb-2">
                                        <span>Total Discount 10%</span>
                                        <div className="icon d-flex float-end">
                                            <i className='bx bx-rupee mt-1'></i>
                                            <span>{(10 / 100) * location.state.totalAmount}/-</span>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="after-discount pb-2"><span>After Discount</span>
                                        <div className="icon d-flex float-end">
                                            <i className='bx bx-rupee mt-1'></i>
                                            <span>{location.state.totalAmount - (10 / 100) * location.state.totalAmount}/-</span>
                                        </div>
                                    </div>
                                    <hr />


                                    <div className="taxes pb-2"> <span>Hotel Taxes</span>
                                        <div className="icon d-flex float-end">
                                            <i className='bx bx-rupee mt-1'></i>
                                            <span>200/-</span>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="t-amount pb-2">
                                        <span>Total Amount</span>

                                        <div className="icon d-flex float-end">
                                            <i className='bx bx-rupee mt-1'></i>
                                            {addExtraBad ? (
                                                <span>

                                                    {((location.state.totalAmount - (10 / 100) * location.state.totalAmount || 0)) + 200 + addExtraBadNumber}/-
                                                </span>
                                            ) : (
                                                <span>{(location.state.totalAmount - (10 / 100) * location.state.totalAmount || 0) + 200}/- </span>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* </Affix> */}
                        </div>

                        <div className="col-lg-8 col-md-12">

                            <div className="contact-form mt-4 mb-4">
                                <div className="form-heading">
                                    <h3 className='mb-4'>Guest Details</h3>
                                </div>
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                    layout="vertical"
                                    autoCapitalize='off'
                                >
                                    <Row gutter={16}>
                                        <Col md={4} className='w-100'>
                                            <Form.Item
                                                label="Title"
                                                className="mb-3 pulse_input"
                                            >
                                                <Select
                                                    onChange={(value) => setSelectTitle(value)}
                                                    placeholder='Select'
                                                    value={selectTitle || "Mr"}
                                                >
                                                    <Option value="Mr">Mr</Option>
                                                    <Option value="Ms">Ms</Option>
                                                    <Option value="Mrs">Mrs</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col md={8} className='w-100'>
                                            <Form.Item
                                                name="firstname"
                                                label="First Name"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "First name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your first name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="lastname"
                                                label="Last Name"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Last name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your last name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>



                                        <Col md={4} className='w-100'>
                                            <Form.Item
                                                label="Dial"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Contact Number  is required",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    name="choice"
                                                    placeholder='Select'
                                                    value={selectedDialCode ? selectedDialCode : "+91"}
                                                    disabled={true}
                                                    readOnly={true}
                                                >
                                                    {getCountryDialCode.map(country => (
                                                        <Option key={country.code} value={country.dial_code}>
                                                            {country.dial_code}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col md={8} className='w-100'>
                                            <Form.Item
                                                name="contact"
                                                label="Contact"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Contact Number  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    maxLength={10}
                                                    placeholder='Enter your contact number'
                                                    prefix={<PhoneOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Email  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your Email'
                                                    prefix={<MailOutlined className="me-2" />}

                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={8} className='w-100'>
                                            <Form.Item
                                                name="gstNumber"
                                                label="GST Number"
                                                className="mb-3 pulse_input"
                                            >
                                                <Input
                                                    placeholder='Enter your GST number'
                                                // prefix={<MailOutlined className="me-2" />}
                                                />

                                            </Form.Item>
                                        </Col>

                                        <Col md={8} className='w-100'>
                                            {/* <Form.Item
                                                name="Country"
                                                label="Country"
                                            > */}
                                            <label className='mb-2'>Country</label>

                                            <Input
                                                placeholder="Country"
                                                className='position-relative'
                                                value={searchQuery}
                                                onChange={searchHandler}
                                                required
                                            />
                                            {(searchQuery !== "" && valueSlectorHandel === true) && (
                                                <div className='searchBarOverlay'>
                                                    <ul style={{ listStyleType: 'none' }}>
                                                        {filteredCountries.map(country => (
                                                            <li
                                                                className='text-decoration-none country-list'
                                                                onClick={() => {
                                                                    eventSelector(country);
                                                                    setSelectedDialCode(country.dial_code);
                                                                }}
                                                                key={country.code}
                                                            >
                                                                {country.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {/* </Form.Item> */}

                                        </Col>

                                        {passwordHide &&
                                            <Col md={8} className='w-100'>
                                                <Form.Item
                                                    name="Passportid"
                                                    label="Passport Number"
                                                    className="mb-3 pulse_input"
                                                >
                                                    <Input
                                                        placeholder='Enter your passport number'
                                                    // prefix={<MailOutlined className="me-2" />}
                                                    />

                                                </Form.Item>
                                            </Col>
                                        }

                                        <div className='checkbox-input mt-2'>
                                            <Form.Item
                                                name="agreement"
                                                valuePropName="checked"
                                                className='mb-0'
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                                    },
                                                ]}

                                            >
                                                <Checkbox>
                                                    <span>I have read and understood the rules & restrictions, user agreement of this fare,the {""}
                                                        {/* <Link to="/payment-policy" target="_blank"  >Privacy Policy</Link> */}
                                                        <span className='privacy-policy' onClick={() => setShowPrivacyPolicy(true)}>Privacy Policy</span> &
                                                        <span className='privacy-policy' onClick={() => setTermsAndConditions(true)}> Terms & Conditions</span> .</span>
                                                </Checkbox>
                                            </Form.Item>

                                            <br />

                                        </div>
                                        <Col md={24} className='w-100'>
                                            <div className="btn-box">
                                                <Form.Item>
                                                    <Button
                                                        htmlType='submit'
                                                        className="btn ">Continue Booking
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Modal
                open={showPrivacyPolicy}
                footer={false}
                onCancel={() => setShowPrivacyPolicy()}
                width={1050}
                centered
            >
                <PrivacyPolicy />
            </Modal>


            <Modal
                open={termsAndConditions}
                footer={false}
                onCancel={() => setTermsAndConditions()}
                width={1050}
                centered
            >
                <TermsAndConditions />
            </Modal>
        </>
    )

}

export default BookNowRoom