import React from 'react'
import './Loader.scss';

function LoaderComp(props) {

    const styleLoader = {
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: 'blur(2px)',
        // background: "rgb(0 0 0 / 21%)",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1",
        borderRadius: "10px"
    };

    return (
        <>
            <div className="preloader" style={styleLoader}>
                <span className="loader"></span>
                <span className="text">Please Wait ....</span>
            </div>
        </>
    )
}


export default LoaderComp
