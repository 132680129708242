import React, { useEffect, useState } from 'react'
import { Button, Input, Form, Select, Card, Alert, Row, Col, Modal } from 'antd';
import { UserOutlined, MailOutlined, EditOutlined, CheckCircleTwoTone, PhoneOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Updatebillinginformation, createProfileUser, getUserDetails, mailVerifyFunction, otpConfirmationEmail } from '../../services/EndPoint';
import { done, sms } from '../../utils/Imagepath';

const CreateProfile = ({ setData }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [formOtpVerify] = Form.useForm();
    const [formBilling] = Form.useForm();
    const { Option } = Select;
    const navigate = useNavigate()
    // const { id } = useParams()
    // console.log("id", id);

    const getPhoneNumber = JSON.parse(localStorage.getItem('phonenumber'));
    const getPhoneCode = JSON.parse(localStorage.getItem('phonecode'));
    const emailAddress = JSON.parse(localStorage.getItem('emailAddress'));
    const getNumber = `+${getPhoneCode}${getPhoneNumber}`;
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageBilling, setSuccessMessageBilling] = useState(false)
    const [dataUser, setDataUser] = useState([]);
    const [personalInformationShow, setPersonalInformationShow] = useState(false);
    const [biilingAddressShow, setBilingAddressShow] = useState(false);
    // const [verifiedStatus, setVPerifiedStatus] = useState(true);
    let isEmailVerifiedEmail = JSON.parse(localStorage.getItem('isEmailVerifiedEmail'));
    let getuserStatus = JSON.parse(localStorage.getItem('userStatus'));

    const [verifiedStatus, setVPerifiedStatus] = useState(() => {
        const storedStatus = localStorage.getItem("verifiedStatus");
        return storedStatus !== null ? JSON.parse(storedStatus) : isEmailVerifiedEmail;
    });


    useEffect(() => {
        localStorage.setItem("verifiedStatus", JSON.stringify(verifiedStatus));
    }, [verifiedStatus]);

    const [statusCheck, setStatusCheck] = useState("")
    const [seccess, setSuccess] = useState("")
    const [emailVerify, setEmailVerify] = useState(false)
    const [otp, setOtp] = useState("")
    const location = useLocation()
    const [email, setEmail] = useState('');

    let userId = JSON.parse(localStorage.getItem('userId'));
    const [invalidMessage, setInvalidMessage] = useState("")

    const onFinish = (values) => {
        setLoading(true);
        let reqBody = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            phone_no: values?.phone_no,
            gender: values?.gender,
            id: userId
        };
        createProfileUser(reqBody)
            .then((response) => {
                setLoading(false);
                setVPerifiedStatus(true);
                setSuccessMessage(true);
                setSuccess(response?.data?.message);
                setData(true);
                localStorage.setItem("userStatus", JSON.stringify(true));
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 2000);
                setPersonalInformationShow(false)
                // navigate("/")
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                console.log("error", error)
            });
    };

    const onFinishBilingAdress = (values) => {
        setLoading(true);
        let reqBody = {
            billing_address: values?.billing_address,
            pincode: values?.pincode,
            state: values?.state,
            id: userId
        };
        Updatebillinginformation(reqBody)
            .then((response) => {
                setLoading(false);
                setSuccessMessageBilling(true);
                setSuccess(response?.data?.message);
                setData(true);
                localStorage.setItem("userStatus", JSON.stringify(true));
                setBilingAddressShow(false)
                setTimeout(() => {
                    setSuccessMessageBilling(false);
                }, 2000);

                //   navigate("/")
                // form.resetFields();
                // setPersonalInformationShow(false)
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                console.log("error", error)
            });
    };

    useEffect(() => {
        if (userId) {
            getUserDetails(userId)
                .then((resp) => {
                    form.setFieldsValue({
                        firstName: resp.data.firstName,
                        lastName: resp.data.lastName,
                        email: emailAddress,
                        phone_no: resp.data.phone_no,
                        gender: resp.data.gender,
                    });
                    formBilling.setFieldsValue({
                        billing_address: resp.data.billing_address,
                        pincode: resp.data.pincode,
                        state: resp.data.state,
                    });
                    setDataUser(resp.data);
                })
                .catch((error) => {
                    console.error("errrr", error);
                });
        }
    }, [personalInformationShow, biilingAddressShow]);


    const onChangeEmailVerify = (value) => {
        setOtp(value)
        console.log('onChangeEmailVerify:', value);
    };




    const mailVerify = (values) => {
        setLoading(true);
        let payload = {
            id: userId,
            email: email ? email : dataUser?.email
        };
        mailVerifyFunction(payload)
            .then((response) => {
                setLoading(false);
                setEmailVerify(true);
                // setTimeout(() => {
                //     setEmailVerify(false);
                // }, 2000);
            })
            .catch((error) => {
                setLoading(false);
                console.log("error", error)
            });
    };

    const handleFinishMailVerify = async () => {

        try {
            const authenticateOtp = {
                email: email ? email : dataUser?.email,
                otp: otp,
            };
            const response = await otpConfirmationEmail(authenticateOtp);
            formOtpVerify.resetFields();
            localStorage.setItem("isEmailVerifiedEmail", JSON.stringify(response?.data?.emailVerified));
            setVPerifiedStatus(true)
            setTimeout(() => {
                setEmailVerify(false);
            }, 2000);
            if (response?.data?.message === "Invalid OTP.") {
                setInvalidMessage(response?.data?.message)
                setTimeout(() => {
                    setInvalidMessage("")
                }, 2000);

            } else {
                setStatusCheck(response?.data?.message)
                setInvalidMessage("")
            }
        } catch (error) {
            console.log("err", error?.valueOtp?.message);
        }
    };


    return (
        <>
            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb  me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><a href="/home" style={{ color: '#000' }}>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{location?.state?.status !== false ? "Edit Profile" : "Create Profile"}</li>
                    </ol>
                </div>
            </nav>


            <div className="container" style={{ maxWidth: "834px" }}>
                <h1 className='main-heading'>{location?.state?.status !== false ? "My Profile" : "Create Profile"}</h1>
                <p>Please fill your information below</p>

                <Card className="create-profile m-auto mb-4">

                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <div className="auth">
                            <div className='d-flex justify-content-between'>
                                <h1 className='main-heading mb-4'>Personal Information</h1>
                               
                                {!personalInformationShow && getuserStatus &&
                                    <Link className='' onClick={() => setPersonalInformationShow(!personalInformationShow)}>Edit</Link>
                                } 
                            </div>
                            {successMessage &&
                                <Alert message={seccess} className='mb-4' type="success" showIcon />
                            }

                            {personalInformationShow || location?.state?.status === false ?

                                <>
                                    <Row gutter={16}>
                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="firstName"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "First name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your first name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="lastName"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Last name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your last name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>


                                        {/* <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="contact"
                                                className="mb-3 pulse_input"
                                            >
                                                <Input
                                                    readOnly
                                                    prefix={<span> <PhoneOutlined className="me-2" />{`+${getPhoneCode ? getPhoneCode : "91"}`}{getPhoneNumber}</span>}
                                                    suffix={<span style={{ color: "#52c41a" }}><CheckCircleTwoTone twoToneColor="#52c41a" /> verified </span>} />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="email"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Mail  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your mail'
                                                    prefix={<MailOutlined className="me-2" />}
                                                    value={email}
                                                    readOnly={isEmailVerifiedEmail ? true : false}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    suffix={
                                                        <>
                                                            {verifiedStatus && (
                                                                <>
                                                                    {isEmailVerifiedEmail === true ? (
                                                                        <span style={{ color: "#52c41a" }}>
                                                                            <CheckCircleTwoTone twoToneColor="#52c41a" /> verified
                                                                        </span>
                                                                    ) : (
                                                                        <Link onClick={mailVerify}>verify</Link>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>

                                                    }
                                                />

                                            </Form.Item>
                                        </Col> */}

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="phone_no"
                                                className="mb-3 pulse_input"
                                            >
                                                <Input
                                                    maxLength={10}
                                                    prefix={<span> <PhoneOutlined className="me-2" />{`+${getPhoneCode ? getPhoneCode : "91"}`}{getPhoneNumber}</span>}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="email"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Mail  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your mail'
                                                    prefix={<MailOutlined className="me-2" />}
                                                    value={emailAddress}
                                                    readOnly
                                                    suffix={<span style={{ color: "#52c41a" }}><CheckCircleTwoTone twoToneColor="#52c41a" /> verified </span>}
                                                />

                                            </Form.Item>
                                        </Col>




                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="gender"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Please select your gender"
                                                    allowClear
                                                >
                                                    <Option value="male">Male</Option>
                                                    <Option value="female">Female</Option>
                                                    <Option value="other">Other</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Button className='next-btn px-4' htmlType="submit">{location?.state?.status !== false ? "Save" : "Submit"}</Button>
                                    {getuserStatus && <Button className='next-btn px-4 mx-2' onClick={() => setPersonalInformationShow(false)}>Cancel</Button>}
                                </>
                                :
                                <Row>
                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>  First Name </span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {dataUser?.firstName}
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>   Last Name </span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {dataUser?.lastName}
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>   Mobile Number </span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {dataUser?.phone_no}
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>    Email ID </span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {emailAddress}
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>   Gender </span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {dataUser?.gender}
                                    </Col>
                                </Row>
                            }
                        </div >
                    </Form>

                </Card>

                <Card className="create-profile m-auto my-4">
                    <Form
                        form={formBilling}
                        onFinish={onFinishBilingAdress}
                    >
                        <div className="auth">
                            <div className='d-flex justify-content-between'>
                                <h1 className='main-heading mb-4'>Billing Information</h1>
                                {!biilingAddressShow && getuserStatus &&
                                    <Link className='' onClick={() => setBilingAddressShow(!biilingAddressShow)}>Edit</Link>
                                }
                            </div>
                            {successMessageBilling &&
                                <Alert message={seccess} className='mb-4' type="success" showIcon />
                            }

                            {biilingAddressShow || location?.state?.status === false ?
                                <>
                                    <Row gutter={16}>
                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="billing_address"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Billing Address name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your Billing Addres'
                                                // prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="pincode"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Pin code is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your pincode'
                                                    maxLength={6}
                                                // prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="state"
                                                className="mb-3 pulse_input"
                                            >
                                                <Input
                                                    placeholder='Enter your state'
                                                // prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>

                                        </Col>


                                    </Row>

                                    <Button className='next-btn px-4' htmlType="submit">{location?.state?.status !== false ? "Save" : "Submit"}</Button>
                                    {getuserStatus && <Button className='next-btn px-4 mx-2' onClick={() => setBilingAddressShow(false)}>Cancel</Button>}
                                </>
                                :
                                <Row>
                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'> Billing Address</span>
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        {dataUser?.billing_address}
                                    </Col>

                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>  Pin Code </span>
                                    </Col>
                                    <Col span={12} className='w-100'>
                                        {dataUser?.pincode}
                                    </Col>


                                    <Col span={12} className='w-100'>
                                        <span className='fw-bolder'>  State </span>
                                    </Col>
                                    <Col span={12} className='w-100'>
                                        {dataUser?.state}
                                    </Col>
                                </Row>}


                        </div >
                    </Form>
                </Card>
            </div>

            <Modal
                open={emailVerify}
                footer={false}
                onCancel={() => setEmailVerify()}
                // width={600}
                centered
            >

                <div className='email-otp-verify'>

                    <Form
                        form={formOtpVerify}
                        onFinish={handleFinishMailVerify}
                    >
                        {!statusCheck ?

                            <div className="auth otp-page">
                                <h1 className='main-heading'>Verify your email</h1>

                                <p>
                                    Verification email has been send to {""}
                                    
                                    <Link onClick={() => setBilingAddressShow(false)}>
                                        {email ? email : dataUser?.email} <EditOutlined />
                                    </Link> {""}
                                    Please check your inbox to verify.
                                </p>

                                <Form.Item
                                    name="otp"
                                    // validateStatus={statusCheck ? "error" : ""}
                                    className="center-error-message"
                                >
                                    <Input.OTP
                                        length={6}
                                        onChange={onChangeEmailVerify}
                                    />
                                </Form.Item>

                                <span style={{ color: "red" }}> {invalidMessage}</span>

                                <div className='d-flex justify-content-between'>
                                    {/* {timerRunning ? (
                                        <p>
                                            Time Remaining : {resendTime}
                                        </p>
                                    ) : (
                                        <p>Didn't recieve code?</p>
                                    )} */}

                                    <div className='d-flex'>
                                        <div className='sms'>
                                            <Button
                                                disabled
                                            ><img src={sms} className='me-2' />Resend</Button>
                                        </div>
                                    </div>
                                </div>

                                <Form.Item>
                                    <div className='next-avg'>
                                        <Button htmlType="submit" className='next-btn'>Submit</Button>
                                    </div>
                                </Form.Item>
                            </div>

                            :
                            <div className="btn-box py-5 text-center">
                                <img src={done} alt='' style={{ width: "150px" }} />
                                <h2 className=''>{statusCheck}</h2>
                            </div>
                        }
                    </Form>

                </div>

            </Modal>

        </>
    )
}

export default CreateProfile