import { useState, createContext, useContext } from 'react';

// Creating the context
export const StatesContext = createContext();

// Custom hook to use the context
export const useStatesContext = () => useContext(StatesContext);

// Provider component for the context
export const AppStates = ({ children }) => {
    // State for modal data
    const [modalData, setModalData] = useState("");
    const [resendTime, setResendTime] = useState(15 * 60);

    // All states object containing modal data and its setter
    const allStates = {
        setModalData: setModalData,
        modalData: modalData,
        setResendTime: setResendTime,
        resendTime: resendTime
    };

    // Providing the context value
    return (
        <StatesContext.Provider value={{ allStates }}>
            {children}
        </StatesContext.Provider>
    );
};

export default AppStates;
