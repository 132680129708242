import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { ArrowRightOutlined, EditOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { done, sms } from '../../utils/Imagepath';
import { otpConfirmationUser, userloginSignUp } from '../../services/EndPoint';
import { useStatesContext } from '../../utils/CreateContext';

function OtpPage({ setOpenOtp, openOtp, setOpenOpenLogin }) {
    // const [resendTime, setResendTime] = useState(15 * 60);
    const [timerRunning, setTimerRunning] = useState(true);

    const getPhoneNumber = JSON.parse(localStorage.getItem('phonenumber'));
    const getPhoneCode = JSON.parse(localStorage.getItem('phonecode'));
    const formLogin = JSON.parse(localStorage.getItem('formLogin'));
    const emailAddress = JSON.parse(localStorage.getItem('emailAddress'));
    const [valueOtp, setValueOtp] = useState("");
    const [statusCheck, setStatusCheck] = useState("")
    const getotp = JSON.parse(localStorage.getItem('otp'))
    const [invalidMessage, setInvalidMessage] = useState("")
    const navigate = useNavigate();
    const { allStates } = useStatesContext();

    const handleResend = async () => {
        try {
            const payload = {
                "recipients": [
                    {
                        email: emailAddress
                    }
                ]
            };

            const response = await userloginSignUp(payload);
            console.log("Resending OTP...");
            setTimerRunning(true);
            allStates?.setResendTime(15 * 60);

        } catch (error) {
            console.log("err", error);
        }
    };

    useEffect(() => {
        let timer;
        if (timerRunning && allStates?.resendTime > 0) {
            timer = setTimeout(() => {
                allStates?.setResendTime(prevTime => prevTime - 1);
            }, 1000);
        } else {
            setTimerRunning(false);
        }
        return () => clearTimeout(timer);
    }, [allStates?.resendTime, timerRunning]);


    const formattedTime = `${String(Math.floor(allStates?.resendTime / 60)).padStart(2, '0')}:${String(allStates?.resendTime % 60).padStart(2, '0')}`.toLowerCase();





    const [form] = Form.useForm();

    
    


    const numberEdit = (getPhoneNumber) => {
        allStates?.setModalData(getPhoneNumber)
        setOpenOtp(false)
        setOpenOpenLogin(true)
    }

    const onChangeEmailVerify = (value) => {
        setValueOtp(value)
        console.log('onChangeEmailVerify:', value);
    };

    // const handleFinish = async () => {
    //     // const otp = valueOtp.join('');
    //     const MobileNumber = getPhoneNumber?.match(/\d/) ?
    //         `+${getPhoneCode ? getPhoneCode : "91"}${getPhoneNumber}`
    //         : getPhoneNumber;
    //     try {
    //         const authenticateOtp = {
    //             MobileNumber: MobileNumber,
    //             otp: valueOtp,
    //         };
    //         const response = await otpConfirmationUser(authenticateOtp);
    //         form.resetFields();
    //         if (response?.data?.message === "Invalid OTP.") {
    //             setInvalidMessage(response?.data?.message)
    //             setTimeout(() => {
    //                 setInvalidMessage("")
    //             }, 2000);
    //         } else {
    //             setStatusCheck(response?.data?.message)
    //             setInvalidMessage("")
    //             setTimeout(() => {
    //                 setOpenOtp(false);
    //                 setStatusCheck("")
    //                 setInvalidMessage("")
    //                 localStorage.setItem("userId", JSON.stringify(response?.data?.id));
    //                 localStorage.setItem("userStatus", JSON.stringify(response?.data?.status));
    //                 localStorage.setItem("isOtpVerifiedEmail", JSON.stringify(response?.data?.isOtpVerified));
    //                 localStorage.setItem("isEmailVerifiedEmail", JSON.stringify(response?.data?.emailVerified));
    //                 console.log("isOtpVerifiedParveen", response?.data);
    //                 if (response?.data.status === false) {
    //                     navigate("/create-profile", { state: { id: response?.data.id, status: response?.data.status } });
    //                 } else {
    //                     navigate("/");
    //                     window.location.reload()
    //                 }
    //             }, 3000);
    //         }
    //     } catch (error) {
    //         console.log("err", error?.valueOtp?.message);
    //     }
    // };

    const handleFinish = async () => {
        try {
            const authenticateOtp = {
                email: emailAddress,
                otp: valueOtp,
            };
            const response = await otpConfirmationUser(authenticateOtp);
            form.resetFields();
            if (response?.data?.message === "Invalid OTP.") {
                setInvalidMessage(response?.data?.message)
                setTimeout(() => {
                    setInvalidMessage("")
                }, 2000);

            } else if (response?.data?.message === "OTP has expired.") {
                setInvalidMessage(response?.data?.message)
                setTimeout(() => {
                    setInvalidMessage("")
                }, 2000);
            }
            else {
                setStatusCheck(response?.data?.message)
                setInvalidMessage("")
                setTimeout(() => {
                    setOpenOtp(false);
                    setStatusCheck("")
                    setInvalidMessage("")
                    localStorage.setItem("userId", JSON.stringify(response?.data?.id));
                    localStorage.setItem("userStatus", JSON.stringify(response?.data?.status));
                    localStorage.setItem("isOtpVerifiedEmail", JSON.stringify(response?.data?.isOtpVerified));
                    localStorage.setItem("isEmailVerifiedEmail", JSON.stringify(response?.data?.emailVerified));
                    if (response?.data.status === false) {
                        navigate("/create-profile", { state: { id: response?.data.id, status: response?.data.status } });
                    } else {
                        navigate("/");
                        window.location.reload()
                    }
                }, 3000);
            }
        } catch (error) {
            console.log("err", error?.valueOtp?.message);
        }
    };

    return (
        <>
            <Modal
                open={openOtp}
                footer={false}
                onCancel={() => setOpenOtp()}
                // width={600}
                centered
            >

                <Form
                    // onFinish={handleFinish}
                    form={form}
                >
                    {!statusCheck ?

                        <div className="auth otp-page">
                            <h1 className='main-heading'>Verify OTP</h1>
                            {/* <Alert
                                className="my-3 px-3"
                                showIcon
                                message={getotp}
                                // description={successMessage.message || "Success"}
                                type="success"
                                closable

                            /> */}
                            {/* <p>Enter 6 digit OTP sent to <Link onClick={() => numberEdit(getPhoneNumber)}>{`+${getPhoneCode ? getPhoneCode : "91"}${getPhoneNumber}`} <EditOutlined /></Link></p> */}
                            <p>Enter 6 digit OTP sent to <Link onClick={() => numberEdit(emailAddress)}>{emailAddress} <EditOutlined /></Link></p>

                            <Form.Item
                                name="otp"
                                className="center-error-message"
                                rules={[{ validator: async () => Promise.resolve() }]}
                            >
                                <Input.OTP
                                    length={6}
                                    value={valueOtp}
                                    // onChange={setValueOtp}
                                    onChange={onChangeEmailVerify}
                                    //    autoSubmit={handleFinish}
                                    inputType="numeric"
                                />
                            </Form.Item>
                            <span style={{ color: "red" }}> {invalidMessage}</span>
                            <div className='d-flex justify-content-between'>
                                {timerRunning ? (
                                    <p>
                                        Time Remaining : {formattedTime}
                                    </p>
                                ) : (
                                    <p>Didn't recieve code?</p>
                                )}

                                <div className='d-flex'>
                                    <div className='whatsApp mx-3'>
                                        {getPhoneNumber?.match(/\d/) &&
                                            <Button className='d-flex align-items-center' onClick={handleResend} disabled={timerRunning}>
                                                <WhatsAppOutlined twoToneColor="#52c41a" /> WhatsApp
                                            </Button>
                                        }

                                    </div>

                                    <div className='sms'>
                                        <Button onClick={handleResend} disabled={timerRunning}><img src={sms} className='me-2' />Resend</Button>
                                    </div>
                                </div>
                            </div>

                            <Form.Item>
                                <div className='next-avg'>
                                    <Button className='next-btn' onClick={() => handleFinish()}>Submit <ArrowRightOutlined /></Button>
                                </div>
                            </Form.Item>

                            <p className='mt-1'>
                                I agree to receive critical messages such as OTP, booking details on WhatsApp.</p>
                        </div>

                        :
                        <div className="btn-box py-5 text-center">
                            <img src={done} alt='' style={{ width: "150px" }} />
                            <h2 className=''>{statusCheck}</h2>
                        </div>
                    }
                </Form>

            </Modal>

        </>
    )
}

export default OtpPage;