import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Tabs, Button, Checkbox } from 'antd';
import { getBookingsForUser } from '../../services/EndPoint';
import "../auth/auth.scss"
import { jsPDF } from "jspdf";

const BookingHisotry = () => {
    const [tabValue, setTabValue] = useState("");
    const [btnHide, setBtnHide] = useState(null)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    let userId = JSON.parse(localStorage.getItem('userId'));
    const onChange = (key) => {
        setTabValue(key);
        setBtnHide(false)
        setCheckedList([])
    };



    const generatePDF = async () => {
        const doc = new jsPDF({});
        const lineHeight = 5;
        let cursorY = 20;

        doc.setFont("helvetica", "bold");
        doc.setTextColor("blue"); // Set text color to red for the "Booking" section
        doc.setFontSize(14); // Increase font size for the "Booking" section

        checkedList.forEach((bookingId, index) => {
            const booking = data.find(item => item.booking_id === bookingId);
            if (booking) {
                const bookingContent = `
                    Booking ${index + 1}
                    ______________________
                     booking ID :  ${booking.booking_id}
                    Reference ID: ${booking.Amount}
                    Check-In Date: ${booking.BookingTo} | Check-Out Date: ${booking.Bookingfrom}
                    Amount : ${booking.Amount}
                    Status: ${booking.PaymentStatus}
                    Amount: ${booking.Amount}
                    Guest Name:${`${booking?.Guest_title} ${booking?.FirstName} ${booking?.LastName}`}
                    Status:  ${booking.PaymentStatus}
                    Amount: ${booking.Amount}
                    Rooms: ${booking.RoomID}
                `;

                doc.setTextColor("black");
                doc.setFontSize(12);


                const lines = doc.splitTextToSize(bookingContent, doc.internal.pageSize.width - 10);
                const heightNeeded = (lines.length + 1) * lineHeight;

                if (cursorY + heightNeeded > doc.internal.pageSize.height - 20) {
                    doc.addPage();
                    cursorY = 20;
                }

                doc.text(lines, 20, cursorY);
                cursorY += heightNeeded;
            }
        });

        doc.save(`${tabValue}.pdf`);
    };



    useEffect(() => {
        setLoading(true);

        let payload = {
            userId: userId,
            Type: tabValue ? tabValue : "completed",
        };
        getBookingsForUser(payload)
            .then((req) => {
                setLoading(false);
                setData(req);
            })
            .catch((err) => {
                console.log("Error fetching bookings:", err);
                setLoading(false);
            });
    }, [tabValue]);


    const OperationsSlot = {
        right: btnHide && (
            <Button className="custom-button" onClick={generatePDF}>
                Download {tabValue} Invoice
            </Button>
        )
    };


    const CheckboxGroup = Checkbox.Group;
    const plainOptions = data.map(item => item.booking_id);
    const defaultCheckedList = [];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);


    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;



    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        if (e.target.checked) {
            setBtnHide(true);
        } else {
            setBtnHide(false);
        }
    };


    const items = [
        {
            label: 'Completed Bookings',
            key: 'completed',
            children: <>
                <Card loading={loading} className="booking-history">
                    <Checkbox onChange={onCheckAllChange} checked={checkAll} indeterminate={indeterminate}>
                        Check all
                    </Checkbox>
                    {data.length !== 0 ? (
                        <Row gutter={[16, 16]}>
                            {data.map((item) => (
                                <Col key={item.booking_id} xs={24} sm={12} lg={12}>
                                    <Card className='booking-card' >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                value={item.booking_id}
                                                onChange={(e) => {
                                                    const newList = e.target.checked
                                                        ? [...checkedList, e.target.value]
                                                        : checkedList.filter(id => id !== e.target.value);
                                                    setCheckedList(newList);
                                                    setBtnHide(newList.length !== 0)
                                                }}
                                                checked={checkedList.includes(item.booking_id)}
                                            />
                                            <h3 className='booking-title' style={{ marginLeft: '10px' }}>Booking Details</h3>
                                            <hr />
                                        </div>
                                        <div>
                                            <table class="booking-table">
                                                <tr>
                                                    <td>Booking ID</td>
                                                    <td style={{ color: '#1890ff', fontSize: '18px', marginBottom: '12px' }}>#{item?.booking_id}</td>
                                                </tr>

                                                <tr>
                                                    <td>Reference ID</td>
                                                    <td>{item?.pg_reference_no}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-In Date</td>
                                                    <td>{item?.BookingTo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-Out Date</td>
                                                    <td>{item?.Bookingfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                    <td>{item?.Amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{item?.PaymentStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Guest Name</td>
                                                    <td>{`${item?.Guest_title} ${item?.FirstName} ${item?.LastName}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{item?.Rooms}{""}Room</td>
                                                    <td>{item?.RoomID}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>Looks empty, You've no canceled Bookings.</p>
                    )}
                </Card>
            </>,
        },
        {
            label: 'Upcoming Bookings',
            key: 'upcoming',
            children: <>
                <Card loading={loading} className="booking-history">
                    <Checkbox onChange={onCheckAllChange} checked={checkAll} indeterminate={indeterminate}>
                        Check all
                    </Checkbox>
                    {data.length !== 0 ? (
                        <Row gutter={[16, 16]}>
                            {data.map((item) => (
                                <Col key={item.booking_id} xs={24} sm={12} lg={12}>
                                    <Card className='booking-card' >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                value={item.booking_id}
                                                onChange={(e) => {
                                                    const newList = e.target.checked
                                                        ? [...checkedList, e.target.value]
                                                        : checkedList.filter(id => id !== e.target.value);
                                                    setCheckedList(newList);
                                                    setBtnHide(newList.length !== 0)
                                                }}
                                                checked={checkedList.includes(item.booking_id)}
                                            />
                                            <h3 className='booking-title' style={{ marginLeft: '10px' }}>Booking Details</h3>
                                            <hr />
                                        </div>
                                        <div>
                                            <table class="booking-table">
                                                <tr>
                                                    <td>Booking ID</td>
                                                    <td style={{ color: '#1890ff', fontSize: '18px', marginBottom: '12px' }}>#{item?.booking_id}</td>
                                                </tr>

                                                <tr>
                                                    <td>Reference ID</td>
                                                    <td>{item?.pg_reference_no}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-In Date</td>
                                                    <td>{item?.BookingTo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-Out Date</td>
                                                    <td>{item?.Bookingfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                    <td>{item?.Amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{item?.PaymentStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Guest Name</td>
                                                    <td>{`${item?.Guest_title} ${item?.FirstName} ${item?.LastName}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{item?.Rooms}{""}Room</td>
                                                    <td>{item?.RoomID}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>Looks empty, You've no canceled Bookings.</p>
                    )}
                </Card>
            </>,
        },
        {
            label: 'Cancelled Bookings',
            key: 'canceled',
            children: <>
                <Card loading={loading} className="booking-history">
                    <Checkbox onChange={onCheckAllChange} checked={checkAll} indeterminate={indeterminate}>
                        Check all
                    </Checkbox>
                    {data.length !== 0 ? (
                        <Row gutter={[16, 16]}>
                            {data.map((item) => (
                                <Col key={item.booking_id} xs={24} sm={12} lg={12}>
                                    <Card className='booking-card' >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                value={item.booking_id}
                                                onChange={(e) => {
                                                    const newList = e.target.checked
                                                        ? [...checkedList, e.target.value]
                                                        : checkedList.filter(id => id !== e.target.value);
                                                    setCheckedList(newList);
                                                    setBtnHide(newList.length !== 0)
                                                }}
                                                checked={checkedList.includes(item.booking_id)}
                                            />
                                            <h3 className='booking-title' style={{ marginLeft: '10px' }}>Booking Details</h3>
                                            <hr />
                                        </div>
                                        <div>
                                            <table class="booking-table">
                                                <tr>
                                                    <td>Booking ID</td>
                                                    <td style={{ color: '#1890ff', fontSize: '18px', marginBottom: '12px' }}>#{item?.booking_id}</td>
                                                </tr>

                                                <tr>
                                                    <td>Reference ID</td>
                                                    <td>{item?.pg_reference_no}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-In Date</td>
                                                    <td>{item?.BookingTo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-Out Date</td>
                                                    <td>{item?.Bookingfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                    <td>{item?.Amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{item?.PaymentStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Guest Name</td>
                                                    <td>{`${item?.Guest_title} ${item?.FirstName} ${item?.LastName}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{item?.Rooms}{""}Room</td>
                                                    <td>{item?.RoomID}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>Looks empty, You've no canceled Bookings.</p>
                    )}
                </Card>
            </>,
        },
        {
            label: 'All Invoice',
            key: 'all',
            children: <>
                <Card loading={loading} className="booking-history">
                    <Checkbox onChange={onCheckAllChange} checked={checkAll} indeterminate={indeterminate}>
                        Check all
                    </Checkbox>
                    {data.length !== 0 ? (
                        <Row gutter={[16, 16]}>
                            {data.map((item) => (
                                <Col key={item.booking_id} xs={24} sm={12} lg={12}>
                                    <Card className='booking-card' >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                value={item.booking_id}
                                                onChange={(e) => {
                                                    const newList = e.target.checked
                                                        ? [...checkedList, e.target.value]
                                                        : checkedList.filter(id => id !== e.target.value);
                                                    setCheckedList(newList);
                                                    setBtnHide(newList.length !== 0)
                                                }}
                                                checked={checkedList.includes(item.booking_id)}
                                            />
                                            <h3 className='booking-title' style={{ marginLeft: '10px' }}>Booking Details</h3>
                                            <hr />
                                        </div>
                                        <div>
                                            <table class="booking-table">
                                                <tr>
                                                    <td>Booking ID</td>
                                                    <td style={{ color: '#1890ff', fontSize: '18px', marginBottom: '12px' }}>#{item?.booking_id}</td>
                                                </tr>

                                                <tr>
                                                    <td>Reference ID</td>
                                                    <td>{item?.pg_reference_no}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-In Date</td>
                                                    <td>{item?.BookingTo}</td>
                                                </tr>
                                                <tr>
                                                    <td>Check-Out Date</td>
                                                    <td>{item?.Bookingfrom}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                    <td>{item?.Amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{item?.PaymentStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Guest Name</td>
                                                    <td>{`${item?.Guest_title} ${item?.FirstName} ${item?.LastName}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>{item?.Rooms}{""}Room</td>
                                                    <td>{item?.RoomID}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>Looks empty, You've no canceled Bookings.</p>
                    )}
                </Card>
            </>,
        },
    ];


    return (
        <div>
            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb  me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><a href="/home" style={{ color: '#000' }}>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Booking Hisotry</li>
                    </ol>
                </div>
            </nav>

            <div className="container my-5">
                <h1 className='main-heading mb-4'>My Bookings</h1>
                <Card className=''>
                    <Tabs
                        defaultActiveKey="completed"
                        onChange={onChange}
                        items={items}
                        tabBarExtraContent={OperationsSlot}
                    />
                </Card>
            </div>

        </div>
    )
}

export default BookingHisotry