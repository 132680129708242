
function HussainSagar() {
    return (
        <>
            <div className="main-banner" style={{background: 'url(assets/images/hussain-sagar.jpg)', backgroundSize: 'cover'}}>
                <div className="banner-caption">
                    <span>Hyderabad</span>
                    <h1>Hussain Sagar</h1>
                </div>
            </div>
            <div className="container">
                <div className="about-destination">
                    <div className="heading">
                        <h3>Hussain Sagar</h3>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <p>Located 3 Km away from The Superb Hotels, Hussain Sagar is among the most visited tourist destinations in Hyderabad. The lake joins two different parts of the city (Hyderabad and Secunderabad) to each other. The lake is covered with three sides by Indira Park, Lumbini Park, and Sanjeevaiah Park. It is among the largest artificial lakes in Asia that were dug out in 1562 AD during the rule of Ibrahim Quli Qutub Shah. Constructed on the side stream of the River Musi, the lake has been entitled after Hussain Shah Wali. Moreover, the motive behind constructing it was to deal with the irrigation requirements of the city. </p>
                        </div>
                    </div>

                </div>
                <div className="other-details">
                    <div className="heading">
                        <h3>Attraction of the Lake</h3>
                    </div>
                    <div className="row mt-3 mb-5">
                        <div className="col-md-6">
                            <p>Sailing, yachting, and boating are some water activities applicable in Hussain Sagar Lake. Plus, the state tourist department provides the latest and modern facilities like motor boats, speed boats, forty-eight seated launches, etc. The Lumbini Park, situated nearby the Hussain Sagar Lake, is the destination of Hyderabad’s most picturesque light and music shows. Laser lights are retained to produce attractive three-dimensional performances that are entertaining and informative.  </p>
                        </div>
                        <div className="col-md-6">
                            <img src="assets/images/other-detail.jpg" className="w-100" alt="details" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-to-reach">
                <div className="container">
                    <div className="box">
                        <div className="heading">
                            <h3>
                                Mediums to Get to Hussain Sagar Lake
                            </h3>
                        </div>
                        <p>The lake is easily reachable from The Superb Hotels as it is only at a distance of 3 Km. You can reach there by multiple local transportation such as taxis, buses, and auto-rickshaws, which are smoothly available all over the city. Moreover, the lake is accessible by train via the Hussain Sagar Junction and metro as well. </p>
                    </div>
                </div>
            </div>
            <div className="additional-details">
                <div className="container">
                    <div className="heading">
                        <h3>Additional Details</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-time-five'></i>
                                </div>
                                <h5>Weekdays Showtimes</h5>
                                <span>7:15 PM</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-time-five'></i>
                                </div>
                                <h5>Weekend Showtimes</h5>
                                <span>Around 7:15 PM, 8:30 PM</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bxs-parking'></i>
                                </div>
                                <h5>Parking Fee</h5>
                                <span>INR 10/- Per Person</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-camera'></i>
                                </div>
                                <h5>Entry Fee</h5>
                                <span>INR 50/- Per Person </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HussainSagar;