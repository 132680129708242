import React, { useEffect, useState } from 'react'
import HotelSearchEngine from './HotelSearchEngine/HotelSearchEngine';
import { Link, useNavigate } from 'react-router-dom';
import './Home.scss';
import { fetchAllData, galleryImage } from '../../services/EndPoint';
import { Carousel, Col, Row, Modal, Image } from 'antd';
import { executiveDoubleBed, suiteDoubleBed, suiteSingleBed } from '../../utils/Imagepath';
import { gallery13, gallery14, gallery15, gallery16, gallery28, photo_gallery } from '../../utils/Imagepath';
import "../gallery/gallery.scss"
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const imgGallery = [
    {
        id: 1,
        imgUrl: gallery13
    },
    {
        id: 2,
        imgUrl: gallery14
    },
    {
        id: 3,
        imgUrl: gallery15
    },
    {
        id: 4,
        imgUrl: gallery16
    },

];

function Home() {
    const [roomCategoryList, setRoomCategoryList] = useState([]);
    const [getImgGallery, setGetImgGallery] = useState([])
    const dateFormat = 'YYYY-MM-DD';
    const dayjs = require('dayjs');
    const currentDate = dayjs();
    const nextDayDate = currentDate.add(1, 'day');
    const currentFormattedDate = currentDate.format(dateFormat);
    const nextDayFormattedDate = nextDayDate.format(dateFormat);


    useEffect(() => {
        galleryImage()
            .then((galleryData) => {
                setGetImgGallery(galleryData?.data?.data);
                console.log("galleryData", galleryData?.data?.data);
            })
            .catch((error) => {
                console.error('Error fetching gallery images:', error);
            });
    }, []);


    useEffect(() => {
        const reqBody = {
            fromDate: currentFormattedDate,
            endDate: nextDayFormattedDate
        };
        fetchAllData(reqBody)
            .then((res) => {
                setRoomCategoryList(res);
            })
            .catch((err) => {
                console.log("err", err)
            })
    }, [])

    function filterUniqueRoomCodes(roomCategoryList) {
        const uniqueRoomCodes = [];
        const filteredData = [];

        roomCategoryList.forEach(item => {
            if (!uniqueRoomCodes.includes(item.room_Code)) {
                uniqueRoomCodes.push(item.room_Code);
                filteredData.push(item);
            }
        });

        return filteredData;
    }

    const uniqueRoomData = filterUniqueRoomCodes(roomCategoryList);
    // console.log(uniqueRoomData);

    const responsiveSettings = [
        {
            breakpoint: 576, // Small devices (landscape phones)
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 768, // Tablets and small desktops
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 820, // Larger tablets and desktops
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 991, // Larger tablets and desktops
            settings: {
                slidesToShow: 2,
            }
        }
    ];



    const roomList = [
        {
            id: 1,
            room_Code: "single-bed-room",
            imagePath: suiteSingleBed
        },
        {
            id: 2,
            room_Code: "double-room",
            imagePath: executiveDoubleBed
        },
        {
            id: 3,
            room_Code: "super-deluxe-room",
            imagePath: suiteDoubleBed
        }
    ];

    const navigate = useNavigate();

    const handleClick = (item) => {
        navigate('search-result', { state: item });
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const [showGallery, setShowGallery] = useState(false);

    const toggleGallery = () => {
        setShowGallery(!showGallery);
    };

    const handleCloseModal = () => {
        setShowGallery(false);
    };

    const galleryOptions = {
        // showFullscreenButton: true,
        // showPlayButton: true,
        // autoPlay: true,
        slideInterval: 2000,
    };

    return (

        <div className='home-page'>
            <div className="container">
                <HotelSearchEngine />
                <div className="strip">
                    <h3>Book Your First Stay With The Superb Hotels <a style={{ textDecoration: 'underline', letterSpacing: '0px' }}>Book Your Room Now</a></h3>
                </div>
            </div>

            {/* <section className="container">
                <img src="assets/images/slide-2.webp" alt="Slide-two" className="w-100 img-fluid" />
            </section> */}

            <section class="image-container">
                <img src="assets/images/slide-2.webp" alt="Your Image" className="w-100 img-fluid" />
            </section>

            <section className="about-us mt-5">
                <div className="container">
                    <div className="row mt-5 ">
                        <div className="col-lg-6 col-12">
                            <div className="about me-lg-4">
                                <div className="main-heading">
                                    <h3 className="sub-title">
                                        Welcome to the superb hotels
                                    </h3>
                                    <h2 className="title">Know More About Us</h2>
                                </div>
                                <div className="content">
                                    <span>The Superb Hotels offer deluxe facilities & accommodation.</span>
                                    <p>
                                        It's located 2 Km from Khairatabad Railways Station and around 15 Km from Shilparamam. Plus, it is centrally situated in the heart of both twin cities Hyderabad & Secunderabad and exactly opposite to the new secretariat of Hyderabad, Telangana. The property is surrounded with beautiful Lumbini park, Sanjeevaiah park, Indira park, NTR Garden.
                                    </p>
                                    <p>
                                        Exactly located in the center of the city it is connected to all tourist spots and the IT Hub of Hyderabad and Secunderabad the hotel is well-maintained, with 24*7 customer service. All the rooms providing a city view have air-conditioners, a television, and an attached bathroom with hot and running water. It also offers amenities like room services, medical assistance, and laundry.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <div className="img-box">
                                        <img src="assets/images/about2.png" alt="about" className='w-100' />
                                    </div>
                                </div>
                                <div className="col-md-6  col-6">
                                    <div className="img-box" style={{ marginTop: "60px" }}>
                                        <img src="assets/images/about1.png" alt="about" className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="room-accommodation">
                <div className="container">
                    <div className="main-heading">
                        <h3 className="sub-title">
                            The Best For Your Family
                        </h3>
                        <h2 className="title">Our Best Rooms</h2>
                    </div>

                    <Row gutter={16}>
                        {uniqueRoomData && uniqueRoomData?.map((item) => {
                            return (
                                <Col md={8}>
                                    <div onClick={() => handleClick(item)} style={{ cursor: "pointer" }}>
                                        <div className='item text-center' key={item} style={{ cursor: "pointer" }} >
                                            <div className="room-content">

                                                {roomList.map(room => {
                                                    if (room.room_Code === item?.room_Code) {
                                                        return (
                                                            <img src={room.imagePath} className="hotel-room-img img-fluid" alt={room.room_Code.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                {/* <img src={item?.image_Url} className="hotel-room-img img-fluid" alt={item?.room_Code.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} /> */}

                                                <div className="room-info">
                                                    <ul>
                                                        <li className="room-service">
                                                            <span>Room Service </span>
                                                        </li>
                                                        <li className="restaurant">
                                                            <span> Restaurant </span>
                                                        </li>
                                                        <li className="internet">
                                                            <span> Internet </span>
                                                        </li>
                                                    </ul>
                                                    <h2 className="room-title">
                                                        {item?.room_Code.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                    </h2>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </section>


            <section className="container hotel_confirm mb-4 services">
                <div className="main-heading">
                    <h3 className="sub-title">
                        THE SUPERB HOTEL
                    </h3>
                    <h2 className="title mb-4">Gallery</h2>
                </div>

                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Image src={selectedImage?.imgUrl ? selectedImage?.imgUrl : gallery28} className="img-fluid rounded" />
                    </Col>
                    <Col md={12} xs={24}>
                        <Row gutter={16}>
                            {imgGallery.map(image => (
                                <Col key={image.id} md={12} xs={24} className=''>
                                    <div className="hover14">
                                        <figure>
                                            <img
                                                src={image?.imgUrl}
                                                className="img-fluid rounded cursor-pointer"
                                                onClick={() => handleImageClick(image)}
                                            />
                                        </figure>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <div className="mb-4 w-100">
                        <div className='more_photos mt-0 float-end'>
                            <Link to="" className='more_photos_link' onClick={toggleGallery}>
                                <img src={photo_gallery} className="mb-0 mx-2" style={{ width: "24px", height: "24px" }} />
                                <span className="more_photos_text">See more photos ({getImgGallery.length})</span>
                            </Link>
                        </div>
                    </div>
                </Row>

                <div>
                    <Modal
                        open={showGallery}
                        footer={null}
                        onCancel={handleCloseModal}
                        centered
                        width={1000}
                        destroyOnClose={true}
                    >
                        {showGallery && (
                            <div className="gallery-container">
                                <ImageGallery
                                    items={getImgGallery.map(image => ({ original: image?.image_url, thumbnail: image?.image_url, fullscreen: image?.image_url }))}
                                    showThumbnails={true}
                                    showBullets={true}
                                    {...galleryOptions}
                                />
                            </div>
                        )}
                    </Modal>



                </div>
            </section>

            <section className="services py-5" style={{ background: "#2098c9" }}>
                <div className="container">
                    <div className="main-heading">
                        <h3 className="sub-title" style={{ color: "#fff" }}>
                            OUR SERVICE
                        </h3>
                        <h2 className="title" style={{ color: "#fff" }}>Hotel Facilities</h2>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="content">
                                    <h3>24/7 Room Service</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src="assets/images/room-service.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="content">
                                    <h3>Restaurant</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src="assets/images/restaurant.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="content">
                                    <h3>Internet</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src="assets/images/internet.png" style={{ width: "70px" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="content">
                                    <h3>Air Conditioning</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src="assets/images/air-conditioner.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="location">
                <div className="container">
                    <div className="main-heading">
                        <h3 className="title">Location & Surroundings</h3>
                    </div>
                    <div className="mt-lg-4 mt-3 mb-5">

                        <Carousel showArrows={true} showThumbs={true} dots dotPosition="bottom" slidesToShow={3} responsive={responsiveSettings} autoplay>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-1.jpg" alt="birla-mandir" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Birla Mandir</h3>
                                        <p>At a distance of around 1 Km from The Superb Hotels, the Birla Mandir was constructed by the Birla family in 1976. The temple is located in the panoramic environment at the elevation of Kala Pahar....</p>
                                        <Link to="/birla-mandir">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-2.jpg" alt="Char-Minar" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Char Minar</h3>
                                        <p>Constructed in Indo-Islamic architectural style, Charminar is a granite, limestone, pulverised marble and mortar structure with around four embellished minarets connected to the four arches. </p>
                                        <Link to="/charminar">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-3.jpg" alt="Hussain-Sagar" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Hussain Sagar</h3>
                                        <p>Located 3 Km away from The Superb Hotels, Hussain Sagar is among the most visited tourist destinations in Hyderabad. The lake joins two different parts of the city (Hyderabad and Secunderabad) to each other.</p>
                                        <Link to="/hussain-sagar">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-4.jpg" alt="Puri-Jagannath-Temple" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Puri Jagannath Temple</h3>
                                        <p>Situated at the heart of the city, in Banjara Hills, Jagannath Temple is Hyderabad’s copy of the real Puri Jagannath Temple, which is situated in Orissa. Moreover, the temple is constructed in a similar style to the Puri temple...</p>
                                        <Link to="/puri-jagannath-temple">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-5.jpg" alt="Golconda-Fort" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Golconda Fort</h3>
                                        <p>Located on the Western part of the amazing city of Hyderabad, Golconda Fort is among the region’s best-protected monuments, The construction of this fort was concluded in the 1600s nd it is well-known...</p>
                                        <Link to="/golconda-fort">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">

                                        <img src="assets/images/loc-6.jpeg" alt="Qutub-Shahi-Tombs" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Qutub Shahi Tombs</h3>
                                        <p>Located in the Ibrahim Bagh, the Qutb Shahi Tombs includes the mosques and tombs run by multiple lords of the Qutub Shahi line. The architecture of this tomb has a glimpse of Indian and Persian...</p>
                                        <Link to="/qutub-shahi-tomb">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-7.jpg" alt="Birla-Planetarium" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Birla Planetarium</h3>
                                        <p>Approximately at a distance of 1.0 Km from The Superb Hotels, the Birla Planetarium is located. It is known as one of the most modest planetariums in India. Despite children, it also attracts other visitors...</p>
                                        <Link to="">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div className="card">
                                    <div className="img-box">
                                        <img src="assets/images/loc-8.jpg" alt="Chowmahalla-Palace" className="w-100" />
                                    </div>
                                    <div className="text-box">
                                        <h3>Chowmahalla Palace</h3>
                                        <p>Built between 1857 and 1869, the Chowmahalla Palace is one of the most beautiful palaces in the country. The word Chowmahalla means Four Palaces. The spectacular monument includes two massive courtyards...</p>
                                        <Link to="">Read More &nbsp; <i className='bx bx-chevron-right'></i></Link>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Home;