import React, { useState } from 'react'
import { Button, Input, Modal, Form, Select, Typography } from 'antd';
import { MobileOutlined, ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import { defaultCountries, FlagImage, parseCountry } from "react-international-phone";
import { indiaFlag } from '../../utils/Imagepath';
import '../auth/auth.scss';
import "react-international-phone/style.css";
import { useNavigate } from 'react-router-dom';
import { userloginSignUp } from '../../services/EndPoint';
import { useStatesContext } from '../../utils/CreateContext';
// import { useData, useStatesContext } from '../../utils/CreateContext';

const Login = ({ OpenLogin, setOpenOpenLogin, setOpenOtp }) => {
    const [country, setCountry] = useState("91");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("")
    const [hide, show] = useState(null)
    const { allStates } = useStatesContext();
   
    const emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
    const [form] = Form.useForm();
    const { Option } = Select;
    const navigate = useNavigate();

    const handleCountryChange = (value) => {
        console.log("handleCountryChange", value);
        setCountry(value);
        localStorage.setItem('phonecode', JSON.stringify(value));
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle >
            {country === "+91" && <img src={indiaFlag} alt="India-flag" style={{ height: "24px", width: "24px" }} />}
            <Select
                style={{ width: '80px' }}
                dropdownStyle={{ maxHeight: '270px', minHeight: "20px", width: '360px' }}
                value={country}
                defaultValue={country}
                onChange={handleCountryChange}
                showSearch
            >
                {defaultCountries?.map((c) => {
                    const parsedCountry = parseCountry(c);
                    return (
                        <Option key={parsedCountry.iso2} value={parsedCountry.dialCode} className="d-flex">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FlagImage
                                    iso2={parsedCountry.iso2}
                                    style={{ marginRight: "8px" }}
                                />
                                <Typography type="secondary">+{parsedCountry.dialCode}</Typography>
                                <Typography className='text-uppercase text-end'>{parsedCountry?.iso2}</Typography>
                                &nbsp; ( <Typography className='country-name'>{parsedCountry.name}</Typography>)
                            </div>
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );

    const enterPhoneNumber = (e) => {
        const result = e.target.value.replace(/[^0-9 ]/gi, "");
        form.setFieldsValue({ contact: result });
        setPhoneNumber(e.target.value);
        localStorage.setItem('phonenumber', JSON.stringify(e.target.value));
    };

    const LoginHandleSubmit = async (e) => {
        const MobileNumber = !phoneNumber?.match(/^\d/) ? phoneNumber : `+${country ? country : "91"}${phoneNumber}`;
        try {
            const payload = {
                MobileNumber: MobileNumber,
            };
            const response = await userloginSignUp(payload);
            setOpenOtp(true);
            setOpenOpenLogin(false);
            form.resetFields();
            localStorage.setItem("otp", JSON.stringify(response?.data?.otp));
        } catch (error) {
            console.log("err", error);
        }
    };

    const enterEmailAddress = (e) => {
        setEmailAddress(e.target.value);
        localStorage.setItem('emailAddress', JSON.stringify(e.target.value));
    };


    const LoginHandleEmailSubmit = async (e) => {
        try {
            const payload = {
                "recipients": [
                    {
                        email: emailAddress
                    }
                ]
            };

            const response = await userloginSignUp(payload);
            setOpenOtp(true);
            setOpenOpenLogin(false);
            allStates?.setResendTime(15 * 60);
            form.resetFields();
            // localStorage.setItem("otp", JSON.stringify(response?.data?.otp));
        } catch (error) {
            console.log("err", error);
        }
    };

    return (
        <>
            <Modal
                open={OpenLogin}
                footer={false}
                onCancel={() => setOpenOpenLogin()}
                centered
            >
                {/* {hide ?
                    <Form
                        form={form}
                        onFinish={LoginHandleSubmit}
                        autoComplete='off'
                    >

                        <div className={country !== "+91" ? "auth arrowcountry" : "auth"}>
                            <h1 className='main-heading'>Login/Signup with <Button onClick={() => show(!hide)}>Phone number</Button></h1>
                            <p>Please enter your phone number to create an account. We'll send you an OTP</p>

                            <Form.Item
                                name="contact"
                                className="mb-3 pulse_input"
                                rules={[
                                    {
                                        required: true,
                                        message: "Mobile number is required",
                                    },
                                ]}
                            >
                                <Input
                                    addonBefore={prefixSelector}
                                    placeholder='Enter your phone number'
                                    prefix={<MobileOutlined className="me-2" />}
                                    onChange={(e) => enterPhoneNumber(e)}
                                    maxLength={10}
                                />
                            </Form.Item>
                            <div className='next-avg'>
                                <Button className='next-btn' htmlType="submit">Next <ArrowRightOutlined /></Button>
                            </div>
                            <p className='mt-3'>By proceeding, you agree to Superb Hotel’s Privacy Policy, User Agreement and Terms of Service</p>
                        </div >
                    </Form> : */}

                <Form
                    form={form}
                    onFinish={LoginHandleEmailSubmit}
                    autoComplete='off'
                >

                    <div className={country !== "+91" ? "auth arrowcountry" : "auth"}>
                        <h1 className='main-heading'>Login/Signup</h1>
                        <p>Please enter your email address to create an account. We'll send you an OTP</p>

                        <Form.Item
                            name="contact"
                            className="mb-3 pulse_input"
                            rules={[
                                {
                                    required: true,
                                    message: "Email is required!",
                                },
                                {
                                    pattern: emailRegex,
                                    message: "Enter valid email!",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Enter your email address'
                                prefix={<MailOutlined className="me-2" />}
                                onChange={(e) => enterEmailAddress(e)}
                            />
                        </Form.Item>

                        <div className='next-avg'>
                            <Button className='next-btn' htmlType="submit">Next <ArrowRightOutlined /></Button>
                        </div>
                        <p className='mt-3'>By proceeding, you agree to Superb Hotel’s Privacy Policy, User Agreement and Terms of Service</p>
                    </div >
                </Form>

                {/* } */}



            </Modal>
        </>
    )
}

export default Login