import './HotelSearchEngine.scss';
import React, { useState, useRef } from 'react';
import { fetchAllData } from '../../../services/EndPoint';
import { useNavigate } from 'react-router-dom';
import LoaderComp from '../../../utils/LoaderComp';
import Modal from '../../../utils/Modal';
import { errorImg } from '../../../utils/Imagepath';
import dayjs from 'dayjs';
import { Button, DatePicker, Popover, Select, Form } from 'antd';
import moment from 'moment/moment';

function HotelSearchEngine() {
    const [DataFound, setDataFound] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectAdults, setSelectAdults] = useState(1);
    const [selectchildren, setSelectchildren] = useState(0);
    const [selectRoom, setSelectRoom] = useState(1)
    const [childAges, setChildAges] = useState(Array(selectchildren).fill(null));
    const [isDisabled, setIsDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const formRef = useRef();
    const Option = Select;
    const dateFormat = 'MMM DD, YYYY';
    const currentFormattedDate = dayjs().format(dateFormat);
    const nextDate = dayjs().add(1, 'day').format(dateFormat);
    const [filterFields, setFilterFields] = useState({
        start_date: null,
        end_date: null,
    });

    let currentOnChageStartDate = filterFields?.start_date;
    let currentOnChageEndDate = filterFields.end_date;

    const fetchAllHotalRooms = () => {
        const reqBody = {
            fromDate: currentOnChageStartDate ? currentOnChageStartDate : dayjs(currentFormattedDate).format("YYYY-MM-DD"),
            endDate: currentOnChageEndDate ? currentOnChageEndDate : dayjs(nextDate).format("YYYY-MM-DD")
        };
        setLoading(true)
        fetchAllData(reqBody)
            .then((res) => {
                setLoading(false)
                if (res && res.length === 0) {
                    setLoading(false)
                    setDataFound(true)
                    setTimeout(() => {
                        setDataFound(false)
                    }, 3000);
                } else {
                    setLoading(false)
                    localStorage.setItem('startDate', JSON.stringify(currentOnChageStartDate ? currentOnChageStartDate : currentFormattedDate));
                    localStorage.setItem('endDate', JSON.stringify(currentOnChageEndDate ? currentOnChageEndDate : nextDate));
                    localStorage.setItem('selectAdults', JSON.stringify(selectAdults));
                    localStorage.setItem('selectChild', JSON.stringify(selectchildren));
                    localStorage.setItem('selectRoom', JSON.stringify(selectRoom));
                    localStorage.setItem('childAges', JSON.stringify(childAges));
                    navigate("/search-result", { state: { state: res } });
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("err", err);
            });
    };

    // const disabledPastDate = (current) => {
    //     return current && current < moment().startOf('day');
    // };

    const disabledPastDate = (current) => {
        const today = moment().startOf('day');
        return current && (current.isBefore(today) || current.isSame(currentOnChageStartDate, 'day'));
    };

    const handleChange = (range) => {
        let dateFrom = range[0].format("YYYY-MM-DD");
        let dateTo = range[1].format("YYYY-MM-DD");
        setFilterFields((prevState) => ({
            ...prevState,
            start_date: dateFrom,
            end_date: dateTo,
        }));
    };


    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const addAdults = () => {
        if (selectAdults < 10) {
            setSelectAdults(selectAdults + 1);
        }
    }

    const removeAdults = () => {
        if (selectAdults > 1) {
            setSelectAdults(prevAdults => prevAdults - 1);
            // When updating selectChildren, ensure it's based on the correct selectAdults count
            setSelectchildren(prevChildren => {
                // Ensure selectChildren is not decremented below 0
                return prevChildren > 0 ? prevChildren - 1 : 0;
            });
        }
    };



    const addRooom = () => {
        if (selectRoom < 10) {
            setSelectRoom(selectRoom + 1);
        }
    }
    const removeRoom = () => {
        if (selectRoom > 1) {
            setSelectRoom(selectRoom - 1);
        }
    }


    const addChildern = () => {
        if (selectchildren < selectAdults + 1) {
            setSelectchildren(selectchildren + 1);
        }
    }

    const removeChildern = () => {
        if (selectchildren > 0) {
            setSelectchildren(selectchildren - 1);
        }
    }


    const onSelectChildAge = (value, index) => {
        setChildAges(prevChildAges => {
            const updatedAges = [...prevChildAges];
            updatedAges[index] = value;
            return updatedAges;
        });
    }



    const applyChanges = () => {
        formRef.current.validateFields()
            .then(() => {
                setOpen(false);
            })
            .catch((error) => {
                console.error('Validation error:', error);
            });
    };

    const content = (
        <>
            <Form ref={formRef} className='popup-form'>
                <div className='popup-inner'>
                    <div className='select-adult mb-3'>
                        <h6>Adults</h6>
                        <span className='selection'>
                            <Button className='me-3' onClick={removeAdults}>-</Button>
                            <span className='me-3'>{selectAdults}</span>
                            <Button onClick={addAdults}>+</Button>
                        </span>
                    </div>

                    <div className='select-room  mb-3'>
                        <h6>Room</h6>
                        <span className='selection'>
                            <Button className='me-3' onClick={removeRoom}>-</Button>
                            <span className='me-3'>{selectRoom}</span>
                            <Button onClick={addRooom}>+</Button>
                        </span>
                    </div>

                    <div className='select-child  mb-3'>
                        <h6>Children</h6>

                        <span className='selection'>
                            <Button className='me-3' onClick={removeChildern}>-</Button>
                            <span className='me-3'>{selectchildren}</span>
                            <Button onClick={addChildern}>+</Button>
                        </span>
                    </div>
                    <span>


                        <div className='row'>
                            {[...Array(selectchildren)].map((_, index) => (
                                <div className='col-md-6' key={index}>
                                    <div className='select-amount'>
                                        <Form.Item
                                            name={`childAge-${index}`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select child age',
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={(value) => onSelectChildAge(value, index)}
                                                defaultValue={null}
                                                placeholder="Select Child age"
                                                style={{ width: '100%' }}
                                                className='mb-2'
                                            >
                                                {[...Array(12)].map((_, age) => (
                                                    <Option key={age} value={age + 1}>
                                                        {age + 1} years old
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </span>
                </div>
                <div className='popup-footer'>
                    <Button onClick={applyChanges} className='apply-btn'>Apply</Button>
                </div>
            </Form>
        </>
    );



    return (
        <>
            <div className="mainSearch">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className='row'>
                            <div className='col-6'>
                                <label for="">Check-In Date</label>

                            </div>
                            <div className='col-6'>
                                <label for="">Check-Out Date</label>

                            </div>
                            <div className='col-12'>
                                <RangePicker
                                    defaultValue={[dayjs(currentFormattedDate), dayjs(nextDate)]}
                                    format={dateFormat}
                                    onChange={handleChange}
                                    disabledDate={disabledPastDate}
                                    className='w-100 border-0'
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-12">
                        <label>Room | Adult | Child</label>

                        <Popover
                            content={content}
                            placement="bottom"
                            trigger="click"
                            open={open}
                            onOpenChange={handleOpenChange}
                        >
                            <div className="select-acr"> {selectAdults} {selectAdults === 1 ? "Adult" : "Adults"}, {selectchildren} {selectchildren === 1 ? "child" : "children"}, {selectRoom} {selectRoom === 1 ? "Room" : "Rooms"}   <i className='bx bx-chevron-down'></i></div>
                        </Popover>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <button
                            // disabled={isDisabled}
                            // className={isDisabled ? "disabled-button" : "search-room"}
                            className="search-room float-end"
                            type="button"
                            onClick={fetchAllHotalRooms}>
                            Find a Room
                        </button>
                    </div>

                </div>
            </div>

            {DataFound &&
                <Modal
                    image={errorImg}
                    messageText="No rooms available. Consider searching for different dates ."
                />}

            {loading && <LoaderComp />}

        </>

    )
}

export default HotelSearchEngine;