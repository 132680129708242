import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.min.css';
import { AppStates } from "./utils/CreateContext";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AppStates>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AppStates>
    </React.StrictMode >
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
// import App from "./App";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'boxicons/css/boxicons.min.css';
// import { AppStates } from "./utils/CreateContext";

// const rootElement = document.getElementById('root');

// ReactDOM.hydrate(
//     <React.StrictMode>
//         <AppStates>
//             <BrowserRouter>
//                 <App />
//             </BrowserRouter>
//         </AppStates>
//     </React.StrictMode >,
//     rootElement
// );

