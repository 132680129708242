import React from 'react';
import '../../Hotel-Policy/HotelPolicy.scss';

function WebTermsUse() {
    return (
        <>
            <div className='container'>
                <div className="row">

                    <div className="col-lg-12">
                        <div className="policy-main shadow-sm mb-5">
                            <div className="policy-header position-relative">
                                <h3 className="heading">Website Terms of Use</h3>
                                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5" style={{ left: '0' }}>
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" style={{ height: '7vh', minHeight: '50px' }}>
                                        <defs>
                                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                        </defs>
                                        <g className="moving-waves">
                                            <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="policy-body p-4">
                                <p>The Superb Hotel website located at superbhotel.in is a copyrighted work belonging to Superb Hotels.</p>
                                <p>By using, accessing, viewing, transmitting, caching, storing or otherwise utilizing any of the services, contents, or functions of this website, you signify your consent to these terms and conditions as set forth herein. The terms and conditions set out below apply to your use of this website and related websites.</p>
                                <ol>
                                    <li>
                                        <h6>Usage of Website </h6>
                                        <p>Superb Hotels grants users a non-transferable, non-exclusive, revocable, limited license to access the website solely for their own personal, noncommercial use. The rights approved to you in these Terms are subject to the following restrictions:</p>
                                        <ul>
                                            <li>you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the website</li>
                                            <li>You shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the website</li>
                                            <li>You shall not access the website to build a similar or competitive website.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h6>Copyright Policy</h6>
                                        <p>Superb Hotels respects the intellectual property of others and asks that users of our website do the same. In connection with our website, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online website who are repeated infringers of intellectual property rights, including copyrights.</p>
                                        <p>All logos, brands and marks appearing on this website, except as otherwise noted, are properties either owned or used under license, by Superb Hotels and/or its associate entities who feature on this website. The use of these properties or any other content on this website, except as provided in these terms and conditions or the website content, is strictly prohibited.</p>
                                    </li>
                                    <li>
                                        <h6>Proprietary Rights</h6>
                                        <p>All materials contained in the website are the copyrighted property of Superb Hotels or its subsidiaries. No part of the website may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, and if found in breach of these terms, Superb Hotels may launch copyright strike claims alongside any other legal action as we deem fit.  Any future release, update, or other addition to functionality of the website shall be subject to these Terms.</p>
                                    </li>
                                    <li>
                                        <h6>Links to other Websites/Third Party Links</h6>
                                        <p>The website may contain links to third-party websites and services, and/or display advertisements for third parties. Such Third-Party Links are not under the control of Superb Hotels, and we are not responsible for any Third-Party Links. Superb Hotels provides access to these Third-Party Links only as a convenience to you and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links.  You use all Third-Party Links at your own risk and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links, the applicable third party's terms and policies apply, including the third party's privacy and data-gathering practices.</p>
                                    </li>
                                    <li>
                                        <h6>Disclaimers</h6>
                                        <p>Superb Hotels and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  We and our suppliers do not guarantee that the website will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, or safe.</p>
                                    </li>
                                    <li>
                                        <h6>Limitation on Liability</h6>
                                        <p>To the maximum extent permitted by law, in no event shall Superb Hotels or our suppliers be liable to you or any third party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the website even if Superb Hotels has been advised of the possibility of such damages.  Access to and use of the website is at your discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.</p>
                                    </li>
                                    <li>
                                        <h6>Termination of Access</h6>
                                        <p>If you breach any of these conditions your license to use the website will terminate immediately without the necessity of any notice being given to you.</p>
                                        <p>Access to the website may be terminated at any time by Superb Hotels without notice.</p>
                                    </li>
                                    
                                    <li>
                                        <h6>Governing Law & Jurisdiction</h6>
                                        <p>These conditions are governed by the laws in force in India and you agree to submit to the exclusive jurisdiction of the courts of India.</p>
                                    </li>
                                    
                                    <li>
                                        <h6>General</h6>
                                        <p>These Terms and Conditions together with Superb Hotels' <b>Privacy Policy</b>, constitute the entire agreement between the parties relating to the use of this website. If any provision of these Terms and Conditions is deemed void, unlawful, or unenforceable for any reason, then that provision shall be severed from the rest of the terms and conditions and shall not affect the validity and enforceability of any of the remaining provisions.</p>
                                    </li>
                                    
                                </ol>
                                <h4>Contact Information</h4>
                                <p><b>Visit:</b> <a href='http://www.superbhotel.in/contact'>www.superbhotel.in/contact</a></p>
                                <p><b>Email:</b> <a href='mailto:reservation@superbhotel.in'>reservation@superbhotel.in</a></p>
                                <p><b>Call:</b> <a href='tel:+040-48551669'>040-48551669</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebTermsUse;