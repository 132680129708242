import React from "react";
import '../Footer/Footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footer-main">

                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <div className="info me-lg-5">
                                <a className="navbar-brand" href="/"><img src="assets/images/logo.png" alt="logo" /></a>
                                <p>The Superb Hotels offer deluxe facilities & accommodation. It's located 2 Km from Khairatabad Railways Station and around 15 Km from Shilparamam. </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <div className="info">
                                <h4>How much far from Airport</h4>
                                <p>Just 22km from the Rajiv Gandhi International Airport, our superb hotel offers a seamless journey, ensuring convenience and comfort for travellers.</p>
                                <div className="social-links mb-4">
                                    <span>Social Links</span>
                                    <ul>
                                        <li><Link to="https://www.facebook.com/thesuperbhotels"><i className='bx bxl-facebook'></i></Link></li>
                                        <li><Link to="https://www.instagram.com/thesuperbhotels/"><i className='bx bxl-instagram' ></i></Link></li>
                                        <li><Link to="https://www.linkedin.com/company/the-superb-hotels/"><i className='bx bxl-linkedin'></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 ps-lg-5">
                            <div className="info">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><Link to="About">About Us</Link></li>
                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/payment-policy">Payment Policy</Link></li>
                                    <li><Link to="/terms-and-conditions">General Terms & Conditionss</Link></li>
                                    <li><Link to="/website-terms-use">Website Terms & Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5">
                            <div className="contact-info">
                                <h4>Contact Details</h4>
                                <ul>
                                    <li className="mb-lg-3"><Link to="/"><b>Mobile : </b> 040-48551669<br />
                                        040-29388551</Link></li>
                                    <li className="mb-lg-3"><Link to="/"><b>Email Id : </b> reservation@superbhotel.in</Link></li>
                                    <li className="mb-lg-3"><Link to="/"><b>Address : </b> 5-9-18/9 Saifabad, Opposite Old Gate Secretariat, Hyderabad 500004</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="footer-bottom-bar">
                    <p>© Copyrights 2023 The Superb Hotels All rights reserved.</p>
                </div>

            </div>
        </footer>
    )
}

export default Footer;