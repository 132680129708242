import React from "react";
import '../../Location-and-surroundings/locationAndsurroundings.scss';

function BirlaMandir(){
 return(
    <>
    <div className="main-banner" style={{background: 'url(assets/images/Birla-Mandir.jpg)', backgroundSize: 'cover'}}>
    <div className="banner-caption">
        <span>Hyderabad</span>
        <h1>Birla Mandir</h1>
    </div>
</div>
<div className="container mt-5">
    <div className="about-destination">
        <div className="heading">
            <h3>
                Birla Mandir
            </h3>
        </div>
        <div className="row mb-3">
            <div className="col-md-12">
                <p>At a distance of around 1 Km from The Superb Hotels, the Birla Mandir was constructed by the Birla family in 1976. The temple is located in the panoramic environment at the elevation of Kala Pahar, which is present at the Southern end of Hussain Sagar Lake. You will surely get a mixture of South Indian and Oriyan styles of architecture. Around 2000 tons of pure white marble is utilised to construct the temple. The carving of God Venkateshwara is built up of granite, and it is approximately 11 ft. tall. On the top of the temple, there is a lotus which creates an umbrella. In addition, there is a flagstaff made of brass inside the temple boundaries with an elevation of 42 ft. There are a few teachings of holy men, and Gurbani is inscribed on the temple walls.</p>
            </div>
        </div>
    </div>
    <div className="culture">
        <div className="row">
            <div className="col-md-6">
                <div className="heading">
                    <h3>Culture</h3>
                </div>
                <p>Apart from being a popular heritage city of India, Hyderabad is also known for its diverse culture. It has a mixed culture of Hindu and Muslim traditions. Moreover, it is a hub for galleries, museums, and other institutions which are prominent tourist attractions. A clear Indo-Islamic architectural style with local contribution is seen in Hyderabad buildings, making it the foremost and Best Heritage City of India since 2012. </p>
            </div>
            <div className="col-md-6">
                <img src="assets/images/culture.jpg" className="w-100" alt="culture" />
            </div>
        </div>
    </div>
</div>
<div className="how-to-reach">
    <div className="container">
        <div className="box">
            <div className="heading">
                <h3>
                    Mediums to Get to the Birla Mandir
                </h3>
            </div>
            <p>There are multiple ways to visit the Birla Mandir. You can take a bus, cabs, taxis, and auto-rickshaws to reach there as it is located in an easily reachable location from The Superb Hotels.</p>
        </div>
    </div>
</div>
<div className="additional-details">
    <div className="container">
        <div className="heading">
            <h3>Additional Details</h3>
        </div>
                    <p className="mb-2 text-center" style={{fontSize: '17px'}}>
                        <b>Timings : </b> Opens at 7 AM -12 PM & 3 PM to 9 PM
                    </p>
                    <p className="text-center" style={{fontSize: '17px'}}>
                        <b>Dress code : </b> Does not have any specific dress code. However, the pilgrims are expected to wear conservative and clean attire while entering the temple.
                    </p>
            </div>
        </div>
    </>
 )
}

export default BirlaMandir;