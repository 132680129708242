import React from 'react';
import '../../Hotel-Policy/HotelPolicy.scss';

function TermsAndConditions() {
    return (
        <>
            <div className='container'>
                <div className="row">

                    <div className="col-lg-12">
                        <div className="policy-main shadow-sm mb-5">
                            <div className="policy-header position-relative">
                                <h3 className="heading">GENERAL TERMS &amp; CONDITIONS FOR THE GUEST</h3>
                                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5" style={{ left: '0' }}>
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" style={{ height: '7vh', minHeight: '50px' }}>
                                        <defs>
                                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                        </defs>
                                        <g className="moving-waves">
                                            <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                            <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="policy-body p-4">
                                <ol>
                                    <li>Check-in Time is 12:00 Noon and Check-Out Time is 11:00 AM.</li>
                                    <li>A Valid Credit / Debit Card /UPI OR Cash deposit is required for online/offline bookings.</li>
                                    <li>Guest must provide Valid Photo ID Proof and Address Proof issued by the respective Governments with Mobile Number. (Aadhaar Card/Driving License/Voter ID Card/Passport copy).
                                        <p className='text-success mt-1'>Note: The PAN Card will not be accepted</p>
                                    </li>
                                    <li>All Foreigners / NRIs should provide their individual Passports, Visa copies, Purpose of Visit/Stay, and Local address (if any) with contact number.</li>
                                    <li>The Primary Guest must be of age 18 years or above to check-in at the hotel. One Child of up to 12 years will be allowed to share the room without any additional charges for using an existing bed. Room occupancy is limited to the number of Guests specified at the time of booking. Extra beds may be available with additional charges.</li>
                                    <li>Early check-in or late check-out is subject to availability and it is chargeable by Superb Hotels.  Please contact the hotel directly for the same.</li>
                                    <li>The Duty Manager or concerned Staff at the Hotel reserves the right to allow Admission OR Accommodation can be denied to guests posing as married couples OR individuals if suitable Photo ID proof is not presented at the time of check-in.</li>
                                    <li>The room tariff is inclusive of all taxes.</li>
                                    <li>Guests are responsible for any damages OR theft caused to hotel property during their stay, and the hotel may charge for repairs or replacement.</li>
                                    <li>The Hotel is not responsible for any loss of money, Jewelry or other valuables during the stay. The guests are requested to take care of their valuable items.</li>
                                    <li>All the guests should maintain tidiness, and hygiene and always adhere to the Safety and security advice duly provided by the Hotel and should avoid breaching the same. The Guest will be solely responsible for any such violation if identified.</li>
                                    <li>The Guest should inform the Hotel Reception in advance about any visitor that comes to meet the Guest at the hotel.  </li>
                                    <li>Disruptive or unruly behaviour of the Guest may result in eviction from the Hotel without a refund.</li>
                                    <li>The Guest should hand over the Room Keys / Cards and settle the dues, if any while Checking out the Hotel. The Room charges do not include charges for other optional services and facilities. These will be charged at the time of check-out from the Superb Hotels.</li>
                                    <li>Alcohol is strictly prohibited.</li>
                                    <li className='text-success'>No smoking - A "no smoking" policy has been implemented by the Indian government for all public areas, including restaurants and hotels. This implies that, with the exception of smoke-friendly guest rooms, smoking is not permitted anywhere within the hotel. When making your reservation, please let us know whether you would like a smoking room.</li>
                                    <li>This property is accessible for wheelchair users. Guests are requested to carry their own wheelchairs for their convenience.</li>
                                    <li>The room tariff is inclusive of all taxes.</li>
                                    <li className='text-success'>If you have requested a specific bed type or any other preference, we will make every effort to achieve it.</li>
                                    <li className='text-success'>We can set up a spare bed in your room for a modest setup fee in addition to any relevant taxes and service charges.</li>
                                    <li className='text-danger'>Kids stay free! Two children below the age of X years stay for free in the same room.</li>
                                    <li>The hotel is not liable for any accidents, injuries or illness that occur during a guest’s stay at the hotel including those related to the use of hotel facilities.</li>
                                    <li>In the event of any kind of emergency like a Flood, Disaster, Earthquake, Curfew or any other natural calamity etc., the Superb Hotel will follow the instructions and guidelines issued by the concerned local government authorities. These instructions are put in place to ensure the safety and well-being of the Guests.</li>
                                    <li>In case of any dispute due to the above terms and conditions or any other legal issues arising, the same will be handled or adjudicated within the jurisdiction of the Telangana Government.</li>
                                </ol>
                                <p>
                                    I have read the above terms and conditions governing my stay in the Superb Hotel and agree to abide by them.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions;