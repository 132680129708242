import React, { useState } from "react";
import '../Contact/Contact.scss';
import { Link } from 'react-router-dom';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row, message, Form, Input, Button } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { contectUsComment } from "../../services/EndPoint";

const Contact = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [messageData, setMessageData] = useState("")
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const payload = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            Phone: values.contact,
            Email: values.email,
            Comment: values.Comment,
            Country: "India"
        }
        contectUsComment(payload)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    setMessageData(response?.data.message)
                    console.log("setMessageData", response?.data.message)

                    const success = () => {
                        messageApi.open({
                            type: 'success',
                            content: <>{messageData ? messageData : "Contact Info Sended successfully"}</>,
                        });
                    };
                    success();
                    form.resetFields()
                } else {

                }
            })
            .catch(error => {
                console.log("err", error);
            });
    };

    return (
        <>
            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb  me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><Link to="/" style={{ color: '#000' }}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact</li>
                    </ol>
                </div>
            </nav>
            <div className="contactBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="box">
                                    <div className="icon">
                                        <i className='bx bx-map'></i>
                                    </div>
                                    <h5>Address </h5>
                                    <p>
                                        5-9-18/9 Saifabad, Opp. Old Gate Secretariat, Hyderabad 500004
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="box">
                                    <div className="icon">
                                        <i className='bx bx-phone'></i>
                                    </div>
                                    <h5>Call Us</h5>
                                    <p>
                                        040-48551669 <br /> 040-29388551
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="box">
                                    <div className="icon">
                                        <i className='bx bx-envelope'></i>
                                    </div>
                                    <h5>Email Us</h5>
                                    <p>
                                        reservation@superbhotel.in
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {contextHolder}

            <div className="contact contactBanner">
                <div className="container">
                    <div className="heading">
                        <h3>Contact Us</h3>
                    </div>

                    <Row gutter={16}>
                        <Col md={12}>
                            <div className="contact-form">
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                    layout="vertical"
                                >
                                    <Row gutter={16}>
                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="FirstName"
                                                label="First Name"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "First name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your first name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="LastName"
                                                label="Last Name"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Last name is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your last name'
                                                    prefix={<UserOutlined className="me-2" />}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Mail  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder='Enter your mail'
                                                    prefix={<MailOutlined className="me-2" />}

                                                />

                                            </Form.Item>
                                        </Col>

                                        <Col md={12} className='w-100'>
                                            <Form.Item
                                                name="contact"
                                                label="Contact"
                                                className="mb-3 pulse_input"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Contact Number  is required",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    maxLength={10}
                                                    placeholder='Enter your contact number'
                                                    prefix={<PhoneOutlined className="me-2" />}
                                                />
                                            </Form.Item>

                                        </Col>



                                        <Col md={24} className='w-100'>
                                            <Form.Item
                                                name="Comment"
                                                label="Comments / Questions"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    rows={3}
                                                    placeholder="Enter your  comments"
                                                />
                                            </Form.Item>
                                        </Col>


                                        <Col md={24} className='w-100'>
                                            <Button htmlType='submit' className="btn-contact w-100 mt-3">Submit</Button>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </Col>

                        <Col md={12} className="w-100">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15228.350699981323!2d78.46942!3d17.4075796!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97997b5fa8cd%3A0x3497930f12657287!2sThe%20Superb%20Hotels!5e0!3m2!1sen!2sin!4v1705406489976!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} loading="lazy"></iframe>
                        </Col>
                    </Row>

                </div>
            </div>

        </>
    )
}

export default Contact;