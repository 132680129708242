import React from "react";


function Charminar() {
    return (
        <>
            <div className="main-banner" style={{background: 'url(assets/images/charminar-bg2.jpg)', backgroundSize: 'cover'}}>
                <div className="banner-caption">
                    <span>Hyderabad</span>
                    <h1>Charminar</h1>
                </div>
            </div>
            <div className="container">
                <div className="about-destination">
                    <div className="heading">
                        <h3>Charminar</h3>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <p>Constructed in Indo-Islamic architectural style, Charminar is a granite, limestone, pulverised marble and mortar structure with around four embellished minarets connected to the four arches. Situated approximately 8 Km away from The Superb Hotels, it is the best example of the city's architectural and heritage legacy. Designed by the Iranian architect established in Hyderabad known as Mir Momin Astarawadi, the grand construction shows the Indo-Islamic style of architecture with guidance taken from the Persian style. </p>
                        </div>
                    </div>

                    <div className="heading">
                        <h3>Areas to Discover Near Charminar</h3>
                    </div>
                    <div className="row mt-5 mb-3">
                        <div className="col-md-3">
                            <div className="card">
                                <img src="assets/images/mecca-masjid.jpg" alt='Mecca-Masjid' />
                                <div className="title">
                                    <h5>Mecca Masjid</h5>
                                    <span>(1 Km)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                                <img src="assets/images/museum.jpg" alt='Nizam-Museum' />
                                <div className="title">
                                    <h5>Nizam Museum</h5>
                                    <span>(1 Km)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                                <img src="assets/images/gulza.jpeg" alt='Gulza-Houz' />
                                <div className="title">
                                    <h5>Gulza Houz</h5>
                                    <span>(230 m)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                                <img src="assets/images/char-kaman.jpg" alt='Char-Kaman' />
                                <div className="title">
                                    <h5>Char Kaman</h5>
                                    <span>(600 m)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                                <img src="assets/images/laad-bazar.jpg" alt='Laad-Bazar' />
                                <div className="title">
                                    <h5>Laad Bazar</h5>
                                    <span>(450 m)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img src="assets/images/iskon-temple.jpg" alt='Iskon-Temple' />
                                <div className="title">
                                    <h5>ISKCON Temple</h5>
                                    <span>(2.4 Km)</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card">
                            <img src="assets/images/koti-residency.jpg" alt='Koti Residency' />
                                <div className="title">
                                    <h5>Koti Residency</h5>
                                    <span>(4.3 Km)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="things-to-do">
                    <div className="heading">
                        <h3>Things to Do in Charminar Area </h3>
                    </div>
                    <p>
                        The area that encircles the minarets, known as the Charminar area, is where you explore multiple shopping options and street grubs in Hyderabad. Moreover, you can visit the following shopping spots in the Charminar:
                    </p>
                    <div className="row mt-5 mb-5">
                        <div className="col-md-11 mx-auto">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <img src="assets/images/one.jpg" alt="Laad-Bazar" />
                                        <div className="border-box shadow-sm">
                                            <div className="title">
                                                <h6>Laad Bazar</h6>
                                                <span>For Dupattas, Jewellery, Bangles</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img src="assets/images/two.jpg" alt="Perfume-Market" />
                                        <div className="border-box shadow-sm" style={{bottom: '0'}}>
                                            <div className="title">
                                                <h6>Perfume Market</h6>
                                                <span>For Local Perfumes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <img src="assets/images/three.jpg" alt="Pathar-Gatti" />
                                        <div className="border-box shadow-sm">
                                            <div className="title">
                                                <h6>Pathar Gatti</h6>
                                                <span>For Pearls</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-to-reach">
                <div className="container">
                    <div className="box">
                        <div className="heading">
                            <h3>Medium to Reach</h3>
                        </div>
                        <p>You can take taxis, buses, cabs, or auto-rickshaws to reach Charminar as it is at a very reachable distance from the hotel.</p>
                    </div>
                </div>
            </div>
            <div className="additional-details">
                <div className="container">
                    <div className="heading">
                        <h3>Additional Details</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-time-five'></i>
                                </div>
                                <h5>Timings</h5>
                                <span>From 9:00 AM- 5:30 PM</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-rupee'></i>
                                </div>
                                <h5>Entry Fee</h5>
                                <span>INR 5 for Indians</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-rupee'></i>
                                </div>
                                <h5>Entry Fee</h5>
                                <span>INR 100 for Foreigners</span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="box">
                                <div className="icon">
                                    <i className='bx bx-camera'></i>
                                </div>
                                <h5>Still & Video Camera</h5>
                                <span>Free</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Charminar;